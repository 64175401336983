/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@import 'bootstrap_variables';
@import '~bootstrap/scss/bootstrap';
@import 'variable';
@import 'app';
@import 'pages';
@import 'sidebar';
@import '../../css/backend/icons/font-awesome/css/fontawesome-all.css';
@import '../../css/backend/icons/simple-line-icons/css/simple-line-icons.css';
@import '../../css/backend/icons/weather-icons/css/weather-icons.min.css';
@import '../../css/backend/icons/linea-icons/linea.css';
@import '../../css/backend/icons/themify-icons/themify-icons.css'; 
@import '../../css/backend/icons/flag-icon-css/flag-icon.min.css';
@import "../../css/backend/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "../../css/backend/magnific-popup.css";
@import "../../css/backend/multi-select.css";
@import 'widgets';
@import 'grid';
@import 'responsive'; 
@import '../../css/backend/spinners.css';
@import '../../css/backend/spinners.css';
@import '../../css/backend/style.css';
@import '~bootstrap-select/sass/bootstrap-select.scss';
@import '~bootstrap-tagsinput/dist/bootstrap-tagsinput.css';
@import '~daterangepicker/daterangepicker.css';
@import 'eli-cocemfe';
@import 'cocemfe';
@import 'issue-location-map';
