@import 'bootstrap_variables';
@import 'variable';

$cocemfeBlue: #003c69!default;
$cocemfeOrange: #df7a00!default;
$cocemfeGreen: #00a599!default;
$statusPending: #ffc70e!default;
$statusReceived: #00aed9!default;
$statusAssigned: #7d5ccb!default;
$statusNotApplicable: #ef473a!default;
$statusDeleted: #ef473a!default;
$statusSolved: #00a599!default;
$statusPostponed: #00b090!default;
$statusProcessing: #00aed9!default;
$statusRevised: #7d5ccb!default;
$statusTransferred: #a5d86a!default;
$lightBg: $light-primary!default;

body {
    min-height: 100vh;
    >#wrapper {
        min-height: 100vh;
        .login-register {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            justify-content: center;
            align-items: center;
            padding: 40px 0;
            .form-error-icon.badge {
                display: none;
            }
        }
    }
}

.navbar-brand {
    max-width: 100%;
    max-height: 70px;
    min-height: 70px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    img.mini {
        display: none;
        margin-top: 0;
        max-width: calc(100% - 10px);
        margin-left: 5px;
    }
    img {
        max-width: 100%;
        max-height: 80px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
    }
}
.mini-sidebar {
    .navbar-brand {
        padding: 0;
        img {
            display: none;
        }
        img.mini {
            display: block;
        }
    }
}
.topbar {
    .top-navbar {
        .app-search {
            margin-top: 0;
        }
        .navbar-nav {
            display: flex;
            align-items: center;
            > .nav-item {
                display: flex;
                justify-content: center;
                > .nav-link {
                    padding-top: 2px;
                }
            }
        }
    }
}

.animationFast {
  animation-duration: 200ms;
}

.profile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
    font-size: 18px!important;
}

.dw-user-box {
    display: flex;
    flex-wrap: nowrap;
}

.delete-icon {
    position: absolute;
    margin-left: 10px;
    color: $danger;
    cursor: pointer;
}
.pagination {
    justify-content: flex-end;
}
.image-thumbnails {
    img {
        max-width: 200px;
    }
}
img.image-thumbnail {
    max-width: 200px;
}
img.image-icon {
    max-width: 32px;
}
img.img-list-thumbnail {
    max-height: 60px;
    width: auto;
}
.flex-end {
    justify-content: flex-end;
}
.flex-start {
    justify-content: flex-start;
}
.flex-align-end {
    align-items: flex-end;
}
.flex-align-start {
    align-items: flex-start;
}
.flex-center {
    justify-content: center;
    align-items: center;
}
img.thumbnail180 {
        max-width: 180px;
}
img.thumbnail {
        max-height: 200px;
        width: auto;
}
form {
    .form-buttons {
        display: flex;
        justify-content: flex-end;
        >.form-group {
            display: block;
            float: right;
            margin-left: 20px;
        }
    }
}
.topbar {
    // Avoid leaflet map overlap
    z-index: 9999;
}
.dropzone .dz-preview .dz-progress {
    display: none!important;
}
.dropzone .dz-preview .dz-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.checkbox {
    label {
        margin-bottom: 0;
    }
}
label.form-check {
    cursor: pointer;
}
.issue-status {
    &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 8px;
        border-radius: 30px;
        vertical-align: middle;
    }
    &.status-pending:before {
        background-color: $statusPending;
    }
    &.status-processing:before {
        background-color: $statusProcessing;
    }
    &.status-solved:before {
        background-color: $statusSolved;
    }
    &.status-not_applicable:before {
        background-color: $statusNotApplicable;
    }
    &.status-deleted:before {
        background-color: $statusDeleted;
    }
    &.status-postponed:before {
        background-color: $statusPostponed;
    }
    &.status-revised:before {
        background-color: $statusRevised;
    }
    &.status-assigned:before {
        background-color: $statusAssigned;
    }
    &.status-received:before {
        background-color: $statusReceived;
    }
    &.status-transferred:before {
        background-color: $statusTransferred;
    }
}
.select2-more-info {
    margin-top: 15px;
    background-color: $lightBg;
    padding: 10px 20px;
    text-align: justify;
    margin-right: 15px;
    margin-left: 15px;
    width: calc(100% - 30px);
}

.select2-container--default .select2-results__group {
    background-color: $lightBg;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 2em;
}
.select2-container--default .select2-results>.select2-results__options {
    max-height: 320px;
}

.select2-container--default .select2-selection--single {
    border-color: #d9d9d9;
    height: 38px; 
    .select2-selection__rendered {
        line-height: 38px; 
    }
    .select2-selection__arrow {
        height: 33px;
    }
}

/* Globals */

.attachments-preview {
    display: flex;
    .attachment-preview {
        margin: 10px;
        width: 200px;
        min-height: 140px;
        font-size: 50px;
        background-color: $lightBg;
        display: flex;
        align-items: center;
        justify-content: center;
        &.small {
            width: 150px;
            min-height: 100px;
            font-size: 40px;
        }
        img {
            max-width: 100%;
        }
        .attachment-file {
            width: 100%;
           height: 100%;
           display: flex;
           align-items: center;
           justify-content: center;
        }
        &.attachment-image {
            background-color: transparent;
            align-items: flex-end;
        }
    }
}

.message-type-button {
    font-size: 18px;
    &.type-system {
        background-color: $info;
    }
    &.type-issue {
        background-color: $danger;
    }
    &.type-answer {
        background-color: $primary;
    }
    &.type-message {
        background-color: $success;
    }
    &.type-alert {
        background-color: $warning;
    }
    i {
        color: white;
    }
}

.message-type-icon {
    font-size: 20px;
    width: 20px;
    text-align: center;
    &.type-system {
        color: $info;
    }
    &.type-issue {
        color: $danger;
    }
    &.type-answer {
        color: $primary;
    }
    &.type-message {
        color: $success;
    }
    &.type-alert {
        color: $warning;
    }
}

.show-more.collapse {
    font-size: 14px;
    line-height: 1.5;
}
.show-more.collapse p {
    margin-bottom: 1.5em;
}

.show-more.collapse:not(.show) {
    height: 42px !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
}

.show-more.collapse.collapsing {
    min-height: 42px !important;
}

.show-more.collapse + a.collapsed .less {
    display: none;
}

.show-more.collapse + a.collapsed .more {
    display: block;
}

.show-more.collapse + a:not(.collapsed) .less {
    display: block;
}

.show-more.collapse + a:not(.collapsed) .more {
    display: none;
}

.show-more.collapse.collapsing + a .less {
    display: none!important;
}

.add-button-container {
    position: relative;
    .add-file-button {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.modal-backdrop + .select2-container {
    z-index: 99999;
}

.list {
    .list-item {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $border-color;
        .list-item-image {
            width: 200px;
            min-height: 140px;
            font-size: 50px;
            &.small {
                width: 150px;
                min-height: 100px;
                font-size: 40px;
            }
            background-color: $lightBg;
            display: flex;
            align-items: center;
            justify-content: center;
            img.image-thumbnail {
                max-width: 100%;
            }
            .attachment-link {
                width: 100%;
               height: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
            }
        }
    }
}

.modal {
    z-index: 10000;
}
.modal-backdrop {
    z-index: 9999;
}

.form-group {
    .select2.select2-container {
        min-width: 100%;
        max-width: 100%;
    }
}
.scroll-sidebar {
    scroll-behavior: auto;
    padding-bottom: 30px;
}

.bootstrap-select .dropdown-menu li a.dropdown-item.active,
.bootstrap-select .dropdown-menu li a.dropdown-item:active,
.dropdown-item.active, 
.dropdown-item:active {
    background: $cocemfeOrange;
    color: white;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-bottom: 5px;
    margin-bottom: 0px;
    margin-top: 2px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid $input-border-color 1px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.bootstrap-tagsinput {
    width: 100%;
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
	content: 'X';
}
.bootstrap-tagsinput .tag {
    height: 100%;
	margin-top: 0;
	float: left;
    font-size: $input-font-size;
    line-height: 1.4em;
}

/* Helpers */

.flex-nowrap {
    flex-wrap: nowrap;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-green {
    background-color: $cocemfeGreen;
}
.bg-white {
    background-color: #ffffff;
}
.bg-light {
    background-color: $lightBg;
}
.font-weight-100 {
    font-weight: 100;
}
.font-weight-200 {
    font-weight: 200;
}
.font-weight-300 {
    font-weight: 300;
}
.font-weight-400 {
    font-weight: 400;
}
.font-weight-500 {
    font-weight: 500;
}
.font-weight-600 {
    font-weight: 600;
}
.font-weight-700 {
    font-weight: 700;
}
.font-weight-800 {
    font-weight: 800;
}
.font-weight-900 {
    font-weight: 900;
}

.d-flex {
    >.card {
        width: 100%;
    }
}

.image-rounded-wrapper {
    position: relative;
    box-shadow: 2px 2px 7px #eee;
    border-radius: 1000px;
    width: 100%;
    padding-bottom: 100%;
    margin: 1em auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;
    img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
    }
}

.grey {
    color: $light-text;
}

.light {
    font-weight: 100;
}

.flex-col {
    flex-direction: column;
}

.flex-col-reverse {
    flex-direction: column-reverse;
}

.flex-row {
    flex-direction: row;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

body.edit-issue .issue_status .dropdown-menu {
    z-index: 9999;
}

.bootstrap-select {
    > .dropdown-toggle {
        height: 100%;
    }
}

[data-toggle="collapse"].collapsed {
    .fa-chevron-up {
        transform: rotate(180deg);
    }
}

.c-pointer {
    cursor: pointer;
}

.h-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.r-0 {
    right: 0 !important;
}
.r-1 {
    right: ($spacer * .25) !important;
}
.r-2 {
    right: ($spacer * .5) !important;
}
.r-3 {
    right: $spacer !important;
}
.r-4 {
    right: ($spacer * 1.5) !important;
}
.r-5 {
    right: ($spacer * 3) !important;
}

.l-0 {
    left: 0 !important;
}
.l-1 {
    left: ($spacer * .25) !important;
}
.l-2 {
    left: ($spacer * .5) !important;
}
.l-3 {
    left: $spacer !important;
}
.l-4 {
    left: ($spacer * 1.5) !important;
}
.l-5 {
    left: ($spacer * 3) !important;
}

.t-0 {
    top: 0 !important;
}
.t-1 {
    top: ($spacer * .25) !important;
}
.t-2 {
    top: ($spacer * .5) !important;
}
.t-3 {
    top: $spacer !important;
}
.t-4 {
    top: ($spacer * 1.5) !important;
}
.t-5 {
    top: ($spacer * 3) !important;
}

.b-0 {
    bottom: 0 !important;
}
.b-1 {
    bottom: ($spacer * .25) !important;
}
.b-2 {
    bottom: ($spacer * .5) !important;
}
.b-3 {
    bottom: $spacer !important;
}
.b-4 {
    bottom: ($spacer * 1.5) !important;
}
.b-5 {
    bottom: ($spacer * 3) !important;
}

.text-small {
    font-size: 0.8em;
}

/* Multiple files custom widget */
.custom-multi-files {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .new-file-widget {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

/* Issues */
body.edit-issue {
    .issue-images {
        display: flex;
        flex-wrap: wrap;
        .issue-images-item {
            position: relative;
            &.dz-preview {
                padding-bottom: 30px!important;
            }
            width: 25%;
            &:first-of-type {
                width: 100%;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            .image-details {
                opacity: 0;
                position: absolute;
                top: 0;
                height: calc(100% - 30px);
                width: 100%;
                padding: 5px;
                left: 0;
                background-color: rgba(255,255,255,0.7);
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                transition: opacity 200ms ease-in-out;
            }
            &:hover {
                .image-details {
                    opacity: 1;
                }
            }
            a.image-popup {
                position: relative;
                z-index: 1;
                height: 100%;
                width: 100%;
                display: inline-block;
                color: $bodytext;
                .image-details {
                    height: 100%;
                }
            }
        }
    }

    button[data-id="form_status"] .filter-option {
        margin-left: 25px;
        line-height: 24px;
    }
    button[data-id="form_status"]:after {
        margin-top: 4px;
    }

    button[data-id="form_status"]:before,
    .option_status.active:before,
    .option_status:before {
        content: '';
        border-radius: 20px;
        width: 15px;
        height: 15px;
        float: left;
        margin-right: 10px;
    }

    .option_status:before {
        margin-top: 3px;
    }

    button[data-class="option_status_pending"]:before ,
    .option_status_pending.active:before,
    .option_status_pending:before {
        background-color: $statusPending!important;
        color: inherit;
    }

    button[data-class="option_status_processing"]:before,
    .option_status_processing.active:before,
    .option_status_processing:before {
        background-color: $statusProcessing!important;
        color: inherit;
    }
    button[data-class="option_status_solved"]:before,
    .option_status_solved.active:before,
    .option_status_solved:before {
        background-color: $statusSolved!important;
        color: inherit;
    }
    button[data-class="option_status_not_applicable"]:before,
    .option_status_not_applicable.active:before,
    .option_status_not_applicable:before {
        background-color: $statusNotApplicable!important;
        color: inherit;
    }
    button[data-class="option_status_deleted"]:before,
    .option_status_deleted.active:before,
    .option_status_deleted:before {
        background-color: $statusDeleted!important;
        color: inherit;
    }
    button[data-class="option_status_postponed"]:before,
    .option_status_postponed.active:before,
    .option_status_postponed:before {
        background-color: $statusPostponed!important;
        color: inherit;
    }
    button[data-class="option_status_revised"]:before,
    .option_status_revised.active:before,
    .option_status_revised:before {
        background-color: $statusRevised!important;
        color: inherit;
    }
    button[data-class="option_status_assigned"]:before,
    .option_status_assigned.active:before,
    .option_status_assigned:before {
        background-color: $statusAssigned!important;
        color: inherit;
    }
    button[data-class="option_status_received"]:before,
    .option_status_received.active:before,
    .option_status_received:before {
        background-color: $statusReceived!important;
        color: inherit;
    }
    button[data-class="option_status_transferred"]:before,
    .option_status_transferred.active:before,
    .option_status_transferred:before {
        background-color: $statusTransferred!important;
        color: inherit;
    }

    .issue_status {
        .option_status {
            //color: white!important;
            border-radius: 0;
            &:hover, &:focus {
                color: $bodytext!important;
            }
        }
        li.active .option_status {
            //font-weight: bold;
            font-style: italic;
        }
        .dropdown-menu {
            padding-top: 0;
            padding-bottom: 0;
            li {
                border-bottom: 1px solid grey;
            }
            li:last-of-type {
                border-bottom: none;
            }
        }
    }
}

/* Sidebar */
.sidebar-nav {
    ul {
        li {
            &.nav-small-cap {
                font-size: 13px;
                text-transform: uppercase;
            }
        }
    }
}

/* View entity */
/* User profile */
.view-entity,
.profile-user {
    .entity-image-wrapper,
    .user-image-wrapper {
        padding: 20px 18%;
        .change-image-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            background-color: rgba(0,0,0,0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 40px;
            color: white;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
        }
        &:hover .change-image-overlay {
            opacity: 1;
        }
    }
}

/* Messages */
.message-row-status-new_answer {
    background-color: rgba(255, 0, 0, 0.05);
}
.message-row-status-new {
    background-color: rgba(0, 0, 255, 0.05);
}

/* Filters */
.filters-wrapper {
    .filter-item {
        position: relative;
        margin-bottom: 8px;
        .filter-label {
            display: flex;
            line-height: 40px;
            align-items: center;
            padding: 4px 8px;
            cursor: pointer;
            i {
                height: 30px;
                width: 32px;
                min-width: 32px;
                background: grey;
                color: white;
                align-items: center;
                display: flex;
                justify-content: center;
                border-radius: 5px;
                margin-right: 10px;
            }
            label {
                margin: 0;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.not-empty {
                background-color: $lightBg;
            }
        }
        &.active {
            .filter-label {
                background-color: $lightBg;
            }
        }
        .filter-content {
            display: none;
            position: absolute;
            width: calc(100% - 30px);
            padding: 10px;
            border: 1px solid $lightBg;
            box-shadow: 1px 2px 8px $lightBg;
            z-index: 9999;
            background-color: white;
            .select2,
            select {
                width: 100%!important;
            }
        }
    }
}

.btn#location-place {
    position: absolute;
    right: 30px;
    top: -30px;
}
.select2-dropdown {
    z-index: 9999;
}

/* Issues map */
#issues-map {
    height: 400px;
    .leaflet-div-icon {
        background-color: transparent;
        border: none;
    }
    .div-marker {
        i {
            font-size: 36px;
            text-shadow: 5px -3px 4px white;
            &.marker-status-pending {
                color: $statusPending;
            }
            &.marker-status-processing {
                color: $statusProcessing;
            }
            &.marker-status-solved {
                color: $statusSolved;
            }
            &.marker-status-not_applicable {
                color: $statusNotApplicable;
            }
            &.marker-status-deleted {
                color: $statusDeleted;
            }
            &.marker-status-postponed {
                color: $statusPostponed;
            }
            &.marker-status-revised {
                color: $statusRevised;
            }
            &.marker-status-assigned {
                color: $statusAssigned;
            }
            &.marker-status-received {
                color: $statusReceived;
            }
            &.marker-status-transferred {
                color: $statusTransferred;
            }
        }
    }
}

#form_issue_types {
    .form-check {
        margin-bottom: 10px;
    }
    .form-check-label {
        margin-left: 10px;
        cursor: pointer;
    }
}

.select2-option-with-icon {
    img {
        height: 32px;
        margin-right: 5px;
    }
}
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle, .bootstrap-select .dropdown-toggle:focus {
    outline: none!important;
}

#form_status {
    /*
    input[type="checkbox"] + .form-check-label:after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 8px;
        border-radius: 30px;
        vertical-align: middle;
    }
    input[value="pending"] + .form-check-label:after {
        background-color: $statusPending;
    }
    input[value="processing"] + .form-check-label:after {
        background-color: $statusProcessing;
    }
    input[value="solved"] + .form-check-label:after {
        background-color: $statusSolved;
    }
    input[value="not_applicable"] + .form-check-label:after {
        background-color: $statusNotApplicable;
    }
    input[value="deleted"] + .form-check-label:after {
        background-color: $statusDeleted;
    }
    input[value="postponed"] + .form-check-label:after {
        background-color: $statusPostponed;
    }
    input[value="revised"] + .form-check-label:after {
        background-color: $statusRevised;
    }
    input[value="assigned"] + .form-check-label:after {
        background-color: $statusAssigned;
    }
    input[value="received"] + .form-check-label:after {
        background-color: $statusReceived;
    }
    */
    input[value="pending"] + .form-check-label:before {
        background-color: $statusPending!important;
    }
    input[value="processing"] + .form-check-label:before {
        background-color: $statusProcessing!important;
    }
    input[value="solved"] + .form-check-label:before {
        background-color: $statusSolved!important;
    }
    input[value="not_applicable"] + .form-check-label:before {
        background-color: $statusNotApplicable!important;
    }
    input[value="deleted"] + .form-check-label:before {
        background-color: $statusDeleted!important;
    }
    input[value="postponed"] + .form-check-label:before {
        background-color: $statusPostponed!important;
    }
    input[value="revised"] + .form-check-label:before {
        background-color: $statusRevised!important;
    }
    input[value="assigned"] + .form-check-label:before {
        background-color: $statusAssigned!important;
    }
    input[value="received"] + .form-check-label:before {
        background-color: $statusReceived!important;
    }
    input[value="transferred"] + .form-check-label:before {
        background-color: $statusTransferred!important;
    }
}

.sidebar-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-item.disabled, .dropdown-item:disabled {
    opacity: 0.6;
}

i.fa {
    &.large {
        font-size: 24px;
    }
}
.logout-link {
    i {
        font-size: 30px;
        color: $cocemfeOrange;
    }
}
.table-responsive .table {
    color: #000000
}
.table th, .table td {
    vertical-align: middle;
}
.clickable-row {
    cursor: pointer;
    transition: background-color 200ms ease-in-out;
    &:hover {
        background-color: lighten($lightBg, 2%);
    }
}
.card-status-pending {
    background-color: $statusPending;
}
.card-status-processing {
    background-color: $statusProcessing;
}
.card-status-solved {
    background-color: $statusSolved;
}
.card-status-not_applicable {
    background-color: $statusNotApplicable;
}
.card-status-deleted {
    background-color: $statusDeleted;
}
.card-status-postponed {
    background-color: $statusPostponed;
}
.card-status-revised {
    background-color: $statusRevised;
}
.card-status-assigned {
    background-color: $statusAssigned;
}
.card-status-received {
    background-color: $statusReceived;
}
.card-status-transferred {
    background-color: $statusTransferred;
}
.img-issue-type-list {
    width: 32px;
    margin-right: 10px;
}
.text-italic {
    font-style: italic;
}
.dashboard-colaboration-wrapper {
    position: absolute;
    bottom: 80px;
    right: 30px;
    text-align: right;
    .logo-fundacion-dashboard {
        max-width: 140px;
    }
    .text-colaboration-dashboard {
        font-weight: 400;
    }
    .logo-zp-dashboard {
        max-width: 100px;
        margin-right: 50px;
    }
}
.dashboard-wrapper {
    padding-bottom: 90px;
}
.topbar .profile-pic {
    width: 30px;
    height: 30px;
}
.btn-cursor-default {
    cursor: default;
}
.white-space-nowrap {
    white-space: nowrap;
}

label.choice_category {
    padding-left: 5px;
    position: relative;
    line-height: 25px;
    cursor: pointer;
    margin-left: 25px;
    &::before {
        -o-transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        background-color: $white;
        border-radius: 1px;
        border: 1px solid $border;
        content: "";
        display: inline-block;
        height: 17px;
        left: 0;
        top:3px;
        margin-left: -20px;
        position: absolute;
        transition: 0.3s ease-in-out;
        width: 17px;
        outline: none;
    }
    &::after {
        color: $dark;
        display: inline-block;
        font-size: 11px;
        height: 16px;
        left: 0;
        margin-left: -20px;
        padding-left: 3px;
        padding-top: 1px;
        position: absolute;
        top: -2px;
        width: 16px;
    }
    &.selected {
        &::after {
            content: "\f00c";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
        }
    }
}

.checkbox-nopadding .checkbox {
    padding-left: 0;
}

.show-on-pdf {
    display: none;
}
.toast-copy {
	position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 9999;
}
.pagination-info {
    line-height: 40px;
}

.checkbox.message-select-all {
    position: absolute;
    left: 13px;
    top: 14px;
}
#reports-chart {
    width: 100%;
    height: 400px;
}
#form_normatives {
    .d-flex {
        .form-group {
            flex-grow: 1;
            margin-left: 15px;
            margin-right: 15px;
            width: 33%;
        }
    }
    .collection-widget {
        display: flex;
        fieldset {
            width: 100%;
        }
        .remove-button {
            height: 40px;
            margin-top: 30px;
        }
    }
    label {
        font-size: 0.9em;
    }
}

.select2-more-info-normative {
    margin-top: 15px;
    background-color: $lightBg;
    padding: 10px 20px;
    text-align: justify;
    margin-right: 15px;
    margin-left: 15px;
    width: calc(100% - 30px);
}

.issue-image-gallery-item {
    width: 60px;
    height: 60px;
    padding: 5px;
    display: inline-block;
    white-space: normal;
    overflow: hidden;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}
#reports-map {
    display: none;
    text-align: center;
    min-height: 100vh;
    >svg {
        //transform: scale(2);
        margin-left: auto;
        margin-right: auto;
        height: 80vh;
    }
}
