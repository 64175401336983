/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900");

@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900");

@import url(../../css/backend/icons/font-awesome/css/fontawesome-all.css);

@import url(../../css/backend/icons/simple-line-icons/css/simple-line-icons.css);

@import url(../../css/backend/icons/weather-icons/css/weather-icons.min.css);

@import url(../../css/backend/icons/linea-icons/linea.css);

@import url(../../css/backend/icons/themify-icons/themify-icons.css);

@import url(../../css/backend/icons/flag-icon-css/flag-icon.min.css);

@import url(../../css/backend/icons/material-design-iconic-font/css/materialdesignicons.min.css);

@import url(../../css/backend/magnific-popup.css);

@import url(../../css/backend/multi-select.css);

@import url(../../css/backend/spinners.css);

@import url(../../css/backend/spinners.css);

@import url(../../css/backend/style.css);

@import url(~bootstrap-tagsinput/dist/bootstrap-tagsinput.css);

@import url(~daterangepicker/daterangepicker.css);

@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900");

:root {
  --blue: #003c69;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #003c69;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #003c69;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #df7a00;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8c8d5;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7a9ab1;
}

.table-hover .table-primary:hover {
  background-color: #a8bccc;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a8bccc;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0085e9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 60, 105, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#28a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23");
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"), "#", "%23") no-repeat right 0.75rem center/8px 10px, str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#28a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23") no-repeat center right 1.75rem/1.125rem 1.125rem;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23");
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: 3.4375rem;
  background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"), "#", "%23") no-repeat right 0.75rem center/8px 10px, str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23") no-repeat center right 1.75rem/1.125rem 1.125rem;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 60, 105, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #003c69;
  border-color: #003c69;
}

.btn-primary:hover {
  color: #fff;
  background-color: #002643;
  border-color: #001f36;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 89, 128, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #003c69;
  border-color: #003c69;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #001f36;
  border-color: #001829;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 89, 128, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #003c69;
  border-color: #003c69;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #003c69;
  border-color: #003c69;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 60, 105, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #003c69;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #003c69;
  border-color: #003c69;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 60, 105, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #003c69;
}

.btn-link:hover {
  color: #df7a00;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:first-child {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.dropdown-item:last-child {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #003c69;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #003c69;
  background-color: #003c69;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 60, 105, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #0085e9;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #1d9eff;
  border-color: #1d9eff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"), "#", "%23");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #003c69;
  background-color: #003c69;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#fff' d='M0 2h4'/%3e%3c/svg%3e"), "#", "%23");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 60, 105, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 60, 105, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#fff'/%3e%3c/svg%3e"), "#", "%23");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 60, 105, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 60, 105, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"), "#", "%23") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #0085e9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 133, 233, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #0085e9;
  box-shadow: 0 0 0 0.2rem rgba(0, 60, 105, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 60, 105, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 60, 105, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 60, 105, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #003c69;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #1d9eff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #003c69;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #1d9eff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #003c69;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #1d9eff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #003c69;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }

  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card {
  overflow: hidden;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #003c69;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #df7a00;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 60, 105, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #003c69;
  border-color: #003c69;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #003c69;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #001f36;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #001f37;
  background-color: #ccd8e1;
  border-color: #b8c8d5;
}

.alert-primary hr {
  border-top-color: #a8bccc;
}

.alert-primary .alert-link {
  color: #000204;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #003c69;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #003c69;
  border-color: #003c69;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #001f37;
  background-color: #b8c8d5;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #001f37;
  background-color: #a8bccc;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #001f37;
  border-color: #001f37;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"), "#", "%23");
}

.carousel-control-next-icon {
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"), "#", "%23");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #003c69 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #001f36 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #003c69 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-3by4::before {
  padding-top: 133.33333%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-3by4::before {
  padding-top: 133.33333%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33.333% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-66 {
  width: 66.666% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-33 {
  height: 33.333% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-66 {
  height: 66.666% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #003c69 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #00101d !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*Theme Colors*/

/*$themecolor: #009efb;*/

/*bootstrap Color*/

/*Light colors*/

/*Normal Color*/

/*Extra Variable*/

/*Preloader*/

.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/******************* 
Global Styles 
*******************/

* {
  outline: none;
}

body {
  background: #fff;
  font-family: "Rubik", sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #4d6074;
  font-weight: 300;
}

html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: #4d6074;
}

a.link:hover,
a.link:focus {
  color: #003c69;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.img-rounded {
  border-radius: 4px;
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4d6074;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

h1 {
  line-height: 48px;
  font-size: 36px;
}

h2 {
  line-height: 36px;
  font-size: 24px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
}

h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}

.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 36px;
}

.display-7 {
  font-size: 2rem;
}

.box {
  border-radius: 4px;
  padding: 10px;
}

html body .dl {
  display: inline-block;
}

html body .db {
  display: block;
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

/*******************
Blockquote
*******************/

blockquote {
  border: 1px solid rgba(120, 130, 140, 0.53);
  border-left: 5px solid #003c69;
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*******************
Paddings
*******************/

html body .p-0 {
  padding: 0px;
}

html body .p-10 {
  padding: 10px;
}

html body .p-20 {
  padding: 20px;
}

html body body .p-30 {
  padding: 30px;
}

html body .p-l-0 {
  padding-left: 0px;
}

html body .p-l-10 {
  padding-left: 10px;
}

html body .p-l-20 {
  padding-left: 20px;
}

html body .p-r-0 {
  padding-right: 0px;
}

html body .p-r-10 {
  padding-right: 10px;
}

html body .p-r-20 {
  padding-right: 20px;
}

html body .p-r-30 {
  padding-right: 30px;
}

html body .p-r-40 {
  padding-right: 40px;
}

html body .p-t-0 {
  padding-top: 0px;
}

html body .p-t-10 {
  padding-top: 10px;
}

html body .p-t-20 {
  padding-top: 20px;
}

html body .p-t-30 {
  padding-top: 30px;
}

html body .p-b-0 {
  padding-bottom: 0px;
}

html body .p-b-5 {
  padding-bottom: 5px;
}

html body .p-b-10 {
  padding-bottom: 10px;
}

html body .p-b-20 {
  padding-bottom: 20px;
}

html body .p-b-30 {
  padding-bottom: 30px;
}

html body .p-b-40 {
  padding-bottom: 40px;
}

/*******************
Margin
*******************/

html body .m-0 {
  margin: 0px;
}

html body .ml-1 {
  margin-left: 5px;
}

html body .ml-2 {
  margin-left: 10px;
}

html body .m-l-15 {
  margin-left: 15px;
}

html body .ml-3 {
  margin-left: 20px;
}

html body .ml-4 {
  margin-left: 30px;
}

html body .ml-5 {
  margin-left: 40px;
}

html body .mr-1 {
  margin-right: 5px;
}

html body .mr-2 {
  margin-right: 10px;
}

html body .m-r-15 {
  margin-right: 15px;
}

html body .mr-3 {
  margin-right: 20px;
}

html body .mr-4 {
  margin-right: 30px;
}

html body .mr-5 {
  margin-right: 40px;
}

html body .mt-0 {
  margin-top: 0px;
}

html body .mt-1 {
  margin-top: 5px;
}

html body .mt-2 {
  margin-top: 10px;
}

html body .m-t-15 {
  margin-top: 15px;
}

html body .mt-3 {
  margin-top: 20px;
}

html body .mt-4 {
  margin-top: 30px;
}

html body .mt-5 {
  margin-top: 40px;
}

html body .mb-0 {
  margin-bottom: 0px;
}

html body .mb-1 {
  margin-bottom: 5px;
}

html body .mb-2 {
  margin-bottom: 10px;
}

html body .m-b-15 {
  margin-bottom: 15px;
}

html body .mb-3 {
  margin-bottom: 20px;
}

html body .mb-4 {
  margin-bottom: 30px;
}

html body .mb-5 {
  margin-bottom: 40px;
}

/*******************
vertical alignment
*******************/

html body .vt {
  vertical-align: top;
}

html body .vm {
  vertical-align: middle;
}

html body .vb {
  vertical-align: bottom;
}

/*******************
font weight
*******************/

html body .font-bold {
  font-weight: 700;
}

html body .font-normal {
  font-weight: normal;
}

html body .font-light {
  font-weight: 300;
}

html body .font-medium {
  font-weight: 400;
}

html body .font-16 {
  font-size: 16px;
}

html body .font-14 {
  font-size: 14px;
}

html body .font-18 {
  font-size: 18px;
}

html body .font-20 {
  font-size: 20px;
}

/*******************
Border
*******************/

html body .b-0 {
  border: none;
}

html body .b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.53);
}

html body .b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.53);
}

html body .b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
}

html body .b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.53);
}

html body .b-all {
  border: 1px solid rgba(120, 130, 140, 0.53) !important;
}

/*******************
Thumb size
*******************/

.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: 4px;
}

/*******************
Text Colors
*******************/

html body .text-muted {
  color: #90a4ae;
}

/*******************
Background Colors
*******************/

html body .bg-primary {
  background-color: #7460ee !important;
}

html body .bg-success {
  background-color: #55ce63 !important;
}

html body .bg-info {
  background-color: #009efb !important;
}

html body .bg-warning {
  background-color: #ffbc34 !important;
}

html body .bg-danger {
  background-color: #f62d51 !important;
}

html body .bg-megna {
  background-color: #01c0c8;
}

html body .bg-theme {
  background-color: #003c69;
}

html body .bg-inverse {
  background-color: #2f3d4a;
}

html body .bg-purple {
  background-color: #7460ee;
}

html body .bg-light-primary {
  background-color: #f1effd;
}

html body .bg-light-success {
  background-color: #e8fdeb;
}

html body .bg-light-info {
  background-color: #cfecfe;
}

html body .bg-light-extra {
  background-color: #ebf3f5;
}

html body .bg-light-warning {
  background-color: #fff8ec;
}

html body .bg-light-danger {
  background-color: #f9e7eb;
}

html body .bg-light-inverse {
  background-color: #f6f6f6;
}

html body .bg-light {
  background-color: #f2f7f8;
}

html body .bg-white {
  background-color: #ffffff;
}

/*******************
Rounds
*******************/

.round {
  line-height: 45px;
  color: #ffffff;
  width: 45px;
  height: 45px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #009efb;
}

.round img {
  border-radius: 100%;
}

.round.round-info {
  background: #009efb;
}

.round.round-warning {
  background: #ffbc34;
}

.round.round-danger {
  background: #f62d51;
}

.round.round-success {
  background: #55ce63;
}

.round.round-primary {
  background: #7460ee;
}

/*******************
Labels
*******************/

.label {
  padding: 2px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: #01c0c8;
}

.label-success {
  background-color: #55ce63;
}

.label-info {
  background-color: #009efb;
}

.label-warning {
  background-color: #ffbc34;
}

.label-danger {
  background-color: #f62d51;
}

.label-megna {
  background-color: #01c0c8;
}

.label-primary {
  background-color: #7460ee;
}

.label-purple {
  background-color: #7460ee;
}

.label-red {
  background-color: #fb3a3a;
}

.label-inverse {
  background-color: #2f3d4a;
}

.label-default {
  background-color: #f2f7f8;
}

.label-white {
  background-color: #ffffff;
}

.label-light-success {
  background-color: #e8fdeb;
  color: #55ce63;
}

.label-light-info {
  background-color: #cfecfe;
  color: #009efb;
}

.label-light-warning {
  background-color: #fff8ec;
  color: #ffbc34;
}

.label-light-danger {
  background-color: #f9e7eb;
  color: #f62d51;
}

.label-light-megna {
  background-color: #e0f2f4;
  color: #01c0c8;
}

.label-light-primary {
  background-color: #f1effd;
  color: #7460ee;
}

.label-light-inverse {
  background-color: #f6f6f6;
  color: #2f3d4a;
}

/*******************
Pagination
*******************/

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination > li > a,
.pagination > li > span {
  color: #263238;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #f2f7f8;
}

.pagination-split li {
  margin-left: 5px;
  display: inline-block;
  float: left;
}

.pagination-split li:first-child {
  margin-left: 0;
}

.pagination-split li a {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #003c69;
  border-color: #003c69;
}

.pager li > a,
.pager li > span {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #263238;
}

/*******************
Table Cell
*******************/

.table-box {
  display: table;
  width: 100%;
}

.table.no-border tbody td {
  border: 0px;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

/* .table td, .table th{
    border-color:$table-border; 
} */

.table thead th,
.table th {
  font-weight: 500;
}

.table-hover tbody tr:hover {
  background: #f2f7f8;
}

html body .jqstooltip {
  width: auto;
  height: auto;
}

.v-middle td,
.v-middle th {
  vertical-align: middle;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*******************
Wave Effects
*******************/

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}

.waves-effect.waves-red .waves-ripple {
  background-color: rgba(244, 67, 54, 0.7);
}

.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 235, 59, 0.7);
}

.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 152, 0, 0.7);
}

.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(156, 39, 176, 0.7);
}

.waves-effect.waves-green .waves-ripple {
  background-color: rgba(76, 175, 80, 0.7);
}

.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(0, 150, 136, 0.7);
}

html body .waves-notransition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-block {
  display: block;
}

/*Badge*/

.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #55ce63;
}

.badge-info {
  background-color: #009efb;
}

.badge-primary {
  background-color: #7460ee;
}

.badge-warning {
  background-color: #ffbc34;
  color: #ffffff;
}

.badge-danger {
  background-color: #f62d51;
}

.badge-purple {
  background-color: #7460ee;
}

.badge-red {
  background-color: #fb3a3a;
}

.badge-inverse {
  background-color: #2f3d4a;
}

/*Text Color*/

.text-white {
  color: #ffffff;
}

.text-danger {
  color: #f62d51 !important;
}

.text-muted {
  color: #90a4ae !important;
}

.text-warning {
  color: #ffbc34 !important;
}

.text-success {
  color: #55ce63 !important;
}

.text-info {
  color: #009efb !important;
}

.text-inverse {
  color: #2f3d4a !important;
}

.text-blue {
  color: #02bec9;
}

.text-purple {
  color: #7460ee;
}

.text-primary {
  color: #7460ee !important;
}

.text-megna {
  color: #01c0c8;
}

.text-dark {
  color: #4d6074;
}

.text-themecolor {
  color: #003c69;
}

/*******************
Buttons
******************/

.btn {
  padding: 7px 12px;
  font-size: 14px;
  cursor: pointer;
}

.btn-group label {
  color: #ffffff !important;
  margin-bottom: 0px;
}

.btn-group label.btn-secondary {
  color: #4d6074 !important;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  width: 50px;
  height: 50px;
  padding: 14px 15px;
  font-size: 18px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: .25rem .5rem;
  font-size: 12px;
}

.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.button-list button,
.button-list a {
  margin: 5px 12px 5px 0;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;
}

.btn-rounded.btn-lg,
.btn-group-lg > .btn-rounded.btn {
  padding: .75rem 1.5rem;
}

.btn-rounded.btn-sm,
.btn-group-sm > .btn-rounded.btn {
  padding: .25rem .5rem;
  font-size: 12px;
}

.btn-rounded.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.btn-custom,
.btn-custom.disabled {
  background: #003c69;
  border: 1px solid #003c69;
  color: #ffffff;
}

.btn-custom:hover,
.btn-custom.disabled:hover {
  background: #003c69;
  opacity: 0.8;
  color: #ffffff;
  border: 1px solid #003c69;
}

.btn-secondary,
.btn-secondary.disabled {
  background: #868e96 !important;
  border: 1px solid #868e96;
  color: #ffffff !important;
}

.btn-secondary:hover,
.btn-secondary.disabled:hover {
  background: #868e96;
  opacity: 0.7;
  border: 1px solid #868e96;
}

.btn-secondary.active,
.btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:focus {
  background: #868e96;
}

.btn-primary,
.btn-primary.disabled {
  background: #7460ee;
  border: 1px solid #7460ee;
}

.btn-primary:hover,
.btn-primary.disabled:hover {
  background: #7460ee;
  opacity: 0.7;
  border: 1px solid #7460ee;
}

.btn-primary.active,
.btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:focus {
  background: #6352ce;
}

.btn-themecolor,
.btn-themecolor.disabled {
  background: #003c69;
  color: #ffffff;
  border: 1px solid #003c69;
}

.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  background: #003c69;
  opacity: 0.7;
  border: 1px solid #003c69;
}

.btn-themecolor.active,
.btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:focus {
  background: #028ee1;
}

.btn-success,
.btn-success.disabled {
  background: #55ce63;
  border: 1px solid #55ce63;
}

.btn-success:hover,
.btn-success.disabled:hover {
  background: #55ce63;
  opacity: 0.7;
  border: 1px solid #55ce63;
}

.btn-success.active,
.btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:focus {
  background: #4ab657;
}

.btn-info,
.btn-info.disabled {
  background: #009efb;
  border: 1px solid #009efb;
}

.btn-info:hover,
.btn-info.disabled:hover {
  background: #009efb;
  opacity: 0.7;
  border: 1px solid #009efb;
}

.btn-info.active,
.btn-info:focus,
.btn-info.disabled.active,
.btn-info.disabled:focus {
  background: #028ee1;
}

.btn-warning,
.btn-warning.disabled {
  background: #ffbc34;
  border: 1px solid #ffbc34;
  color: #ffffff;
}

.btn-warning:hover,
.btn-warning.disabled:hover {
  background: #ffbc34;
  color: #ffffff;
  opacity: 0.7;
  border: 1px solid #ffbc34;
}

.btn-warning.active,
.btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:focus {
  background: #e9ab2e;
}

.btn-danger,
.btn-danger.disabled {
  background: #f62d51;
  border: 1px solid #f62d51;
}

.btn-danger:hover,
.btn-danger.disabled:hover {
  background: #f62d51;
  opacity: 0.7;
  border: 1px solid #f62d51;
}

.btn-danger.active,
.btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:focus {
  background: #e6294b;
}

.btn-inverse,
.btn-inverse.disabled {
  background: #2f3d4a;
  border: 1px solid #2f3d4a;
  color: #ffffff;
}

.btn-inverse:hover,
.btn-inverse.disabled:hover {
  background: #2f3d4a;
  opacity: 0.7;
  color: #ffffff;
  border: 1px solid #2f3d4a;
}

.btn-inverse.active,
.btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:focus {
  background: #232a37;
  color: #ffffff;
}

.btn-red,
.btn-red.disabled {
  background: #fb3a3a;
  border: 1px solid #fb3a3a;
  color: #ffffff;
}

.btn-red:hover,
.btn-red.disabled:hover {
  opacity: 0.7;
  border: 1px solid #fb3a3a;
  background: #fb3a3a;
}

.btn-red.active,
.btn-red:focus,
.btn-red.disabled.active,
.btn-red.disabled:focus {
  background: #e6294b;
}

.btn-outline-default {
  background-color: #ffffff;
}

.btn-outline-default:hover,
.btn-outline-default:focus,
.btn-outline-default.focus {
  background: #f2f7f8;
}

.btn-outline-primary {
  color: #7460ee;
  background-color: #ffffff;
  border-color: #7460ee;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  background: #7460ee;
  color: #ffffff;
  border-color: #7460ee;
}

.btn-outline-success {
  color: #55ce63;
  background-color: transparent;
  border-color: #55ce63;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success.focus {
  background: #55ce63;
  border-color: #55ce63;
  color: #ffffff;
}

.btn-outline-info {
  color: #009efb;
  background-color: transparent;
  border-color: #009efb;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus {
  background: #009efb;
  border-color: #009efb;
  color: #ffffff;
}

.btn-outline-warning {
  color: #ffbc34;
  background-color: transparent;
  border-color: #ffbc34;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  background: #ffbc34;
  border-color: #ffbc34;
  color: #ffffff;
}

.btn-outline-danger {
  color: #f62d51;
  background-color: transparent;
  border-color: #f62d51;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  background: #f62d51;
  border-color: #f62d51;
  color: #ffffff;
}

.btn-outline-red {
  color: #fb3a3a;
  background-color: transparent;
  border-color: #fb3a3a;
}

.btn-outline-red:hover,
.btn-outline-red:focus,
.btn-outline-red.focus {
  background: #fb3a3a;
  border-color: #fb3a3a;
  color: #ffffff;
}

.btn-outline-inverse {
  color: #2f3d4a;
  background-color: transparent;
  border-color: #2f3d4a;
}

.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse.focus {
  background: #2f3d4a;
  border-color: #2f3d4a;
  color: #ffffff;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: #6352ce;
  border: 1px solid #6352ce;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: #4ab657;
  border: 1px solid #4ab657;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #e6294b;
  border: 1px solid #e6294b;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #232a37;
  border: 1px solid #232a37;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}

.button-box .btn {
  margin: 0 8px 8px 0px;
}

.btn-label {
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin: -6px 12px -6px -14px;
  padding: 7px 15px;
}

.btn-facebook {
  color: #ffffff;
  background-color: #3b5998;
}

.btn-twitter {
  color: #ffffff;
  background-color: #55acee;
}

.btn-linkedin {
  color: #ffffff;
  background-color: #007bb6;
}

.btn-dribbble {
  color: #ffffff;
  background-color: #ea4c89;
}

.btn-googleplus {
  color: #ffffff;
  background-color: #dd4b39;
}

.btn-instagram {
  color: #ffffff;
  background-color: #3f729b;
}

.btn-pinterest {
  color: #ffffff;
  background-color: #cb2027;
}

.btn-dropbox {
  color: #ffffff;
  background-color: #007ee5;
}

.btn-flickr {
  color: #ffffff;
  background-color: #ff0084;
}

.btn-tumblr {
  color: #ffffff;
  background-color: #32506d;
}

.btn-skype {
  color: #ffffff;
  background-color: #00aff0;
}

.btn-youtube {
  color: #ffffff;
  background-color: #bb0000;
}

.btn-github {
  color: #ffffff;
  background-color: #171515;
}

/*******************
Notify
*******************/

.notify {
  position: relative;
  top: -25px;
  right: -7px;
}

.notify .heartbit {
  position: absolute;
  top: -20px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid #f62d51;
  border-radius: 70px;
  -moz-animation: heartbit 1s ease-out;
  -moz-animation-iteration-count: infinite;
  -o-animation: heartbit 1s ease-out;
  -o-animation-iteration-count: infinite;
  -webkit-animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.notify .point {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #f62d51;
  position: absolute;
  right: 6px;
  top: -10px;
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0.0;
  }

  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0.0;
  }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
}

/*******************
Checkbox
*******************/

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: block;
  padding-left: 5px;
  position: relative;
}

.checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 1px;
  border: 1px solid rgba(120, 130, 140, 0.53);
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  top: 3px;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none;
}

.checkbox label::after {
  color: #263238;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: 3px;
  width: 16px;
}

.checkbox input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  position: absolute;
  left: 0px;
  outline: none;
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}

.checkbox input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #f2f7f8;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single label {
  height: 17px;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #7460ee;
  border-color: #7460ee;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #f62d51;
  border-color: #f62d51;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #009efb;
  border-color: #009efb;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #55ce63;
  border-color: #55ce63;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-purple input[type="checkbox"]:checked + label::before {
  background-color: #7460ee;
  border-color: #7460ee;
}

.checkbox-purple input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-red input[type="checkbox"]:checked + label::before {
  background-color: #f62d51;
  border-color: #f62d51;
}

.checkbox-red input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

.checkbox-inverse input[type="checkbox"]:checked + label::before {
  background-color: #2f3d4a;
  border-color: #2f3d4a;
}

.checkbox-inverse input[type="checkbox"]:checked + label::after {
  color: #ffffff;
}

/*******************
Radios 
*******************/

.radio {
  padding-left: 20px;
}

.radio label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
}

.radio label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid rgba(120, 130, 140, 0.53);
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  outline: none;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 17px;
  outline: none;
}

.radio label::after {
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #263238;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 7px;
  left: 5px;
  margin-left: -20px;
  position: absolute;
  top: 5px;
  transform: scale(0, 0);
  transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 7px;
}

.radio input[type="radio"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none;
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}

.radio input[type="radio"]:checked + label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single label {
  height: 17px;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #7460ee;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #7460ee;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #7460ee;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #f62d51;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #f62d51;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #f62d51;
}

.radio-info input[type="radio"] + label::after {
  background-color: #009efb;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #009efb;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #009efb;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #ffbc34;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffbc34;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffbc34;
}

.radio-success input[type="radio"] + label::after {
  background-color: #55ce63;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #55ce63;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #55ce63;
}

.radio-purple input[type="radio"] + label::after {
  background-color: #7460ee;
}

.radio-purple input[type="radio"]:checked + label::before {
  border-color: #7460ee;
}

.radio-purple input[type="radio"]:checked + label::after {
  background-color: #7460ee;
}

.radio-red input[type="radio"] + label::after {
  background-color: #f62d51;
}

.radio-red input[type="radio"]:checked + label::before {
  border-color: #f62d51;
}

.radio-red input[type="radio"]:checked + label::after {
  background-color: #f62d51;
}

.checkbox label,
.radio label {
  cursor: pointer;
}

/*******************
File Upload 
******************/

.fileupload {
  overflow: hidden;
  position: relative;
}

.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/*******************
Megamenu
******************/

.mega-dropdown {
  position: static;
  width: 100%;
}

.mega-dropdown .dropdown-menu {
  width: 100%;
  padding: 30px;
  margin-top: 0px;
  background: url(../../images/backend/background/megamenubg.jpg) no-repeat right bottom #fff;
}

.mega-dropdown ul {
  padding: 0px;
}

.mega-dropdown ul li {
  list-style: none;
}

.mega-dropdown .carousel-item .container {
  padding: 0px;
}

.mega-dropdown .nav-accordion .card {
  margin-bottom: 1px;
}

.mega-dropdown .nav-accordion .card-header {
  background: #ffffff;
}

.mega-dropdown .nav-accordion .card-header h5 {
  margin: 0px;
}

.mega-dropdown .nav-accordion .card-header h5 a {
  text-decoration: none;
  color: #4d6074;
}

/*******************
List-style-none
******************/

ul.list-style-none {
  margin: 0px;
  padding: 0px;
}

ul.list-style-none li {
  list-style: none;
}

ul.list-style-none li a {
  color: #4d6074;
  padding: 8px 0px;
  display: block;
  text-decoration: none;
}

ul.list-style-none li a:hover {
  color: #003c69;
}

/*******************
dropdown-item
******************/

.dropdown-item {
  padding: 8px 1rem;
  color: #4d6074;
}

/*******************
Custom-select
******************/

.custom-select {
  background: url(../../images/backend/custom-select.png) right 0.75rem center no-repeat;
}

/*******************
textarea
******************/

textarea {
  resize: none;
}

/*******************
Form-control
******************/

.form-control {
  color: #4d6074;
  display: initial;
}

.form-control-sm {
  min-height: 20px;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.7;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #55ce63;
}

form label {
  font-weight: 400;
}

.form-group {
  margin-bottom: 25px;
}

.form-horizontal label {
  margin-bottom: 0px;
}

.form-control-static {
  padding-top: 0px;
}

.form-bordered .form-group {
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
  padding-bottom: 20px;
}

/*******************
Layouts
******************/

/*Card-noborders*/

.card-no-border {
  /* .left-sidebar, .sidebar-nav{
        background:$sidebar-white;
        
    }
    .sidebar-nav>ul>li>a.active{
        background:$light;
    } */
}

.card-no-border .card {
  border: 0px;
}

.card-no-border .sidebar-footer {
  background: #ffffff;
}

.card-no-border .page-wrapper {
  background: #f2f7f8;
}

.card-no-border .left-sidebar {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.card-no-border .shadow-none {
  box-shadow: none;
}

.card-outline-danger,
.card-outline-info,
.card-outline-warning,
.card-outline-success,
.card-outline-primary {
  background: #ffffff;
}

.card-no-border .card-group .card {
  border: 1px solid rgba(120, 130, 140, 0.53);
}

.card-header {
  background-color: #dae6e8;
}

/*******************/

/*widgets -app*/

/*******************/

.css-bar:after {
  z-index: 1;
}

.css-bar > i {
  z-index: 10;
}

/*******************/

/*single column*/

/*******************/

.single-column .left-sidebar {
  display: none;
}

.single-column .page-wrapper {
  margin-left: 0px;
}

/*******************/

/*Documentation*/

/*******************/

.fix-width {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

ul.common li {
  display: inline-block;
  line-height: 40px;
  list-style: outside none none;
  width: 48%;
}

/***********************
 12/02/2018 (New Update)
***********************/

.progress {
  height: auto;
}

.card-group {
  margin-bottom: 30px;
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*============================================================== 
 For all pages 
 ============================================================== */

#main-wrapper {
  width: 100%;
}

.boxed #main-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
}

.boxed #main-wrapper .sidebar-footer {
  position: absolute;
}

.boxed #main-wrapper .footer {
  display: none;
}

.page-wrapper {
  background: #fff;
  padding-bottom: 60px;
}

.container-fluid {
  padding: 25px 30px;
}

/*******************
 Topbar
*******************/

.topbar {
  position: relative;
  z-index: 50;
}

.topbar .top-navbar {
  min-height: 70px;
  padding: 0px 15px 0 0;
}

.topbar .top-navbar .dropdown-toggle::after {
  display: none;
}

.topbar .top-navbar .navbar-header {
  line-height: 65px;
  text-align: center;
}

.topbar .top-navbar .navbar-header .navbar-brand {
  margin-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
  display: none;
}

.topbar .top-navbar .navbar-header .navbar-brand b {
  line-height: 70px;
  display: inline-block;
}

.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: 17px;
  line-height: 50px;
}

.topbar .top-navbar .navbar-nav > .nav-item.show {
  background: rgba(0, 0, 0, 0.05);
}

.topbar .top-navbar .app-search {
  position: relative;
  margin-top: 13px;
  margin-right: 10px;
  display: inline-block;
}

.topbar .top-navbar .app-search input {
  width: 200px;
  border-radius: 100px;
  font-size: 14px;
  transition: 0.5s ease-in;
}

.topbar .top-navbar .app-search input:focus {
  width: 240px;
}

.topbar .top-navbar .app-search .srh-btn {
  position: absolute;
  top: 8px;
  cursor: pointer;
  background: #ffffff;
  width: 15px;
  height: 15px;
  right: 10px;
  font-size: 14px;
}

.topbar .profile-pic {
  width: 30px;
  border-radius: 100%;
}

.topbar .dropdown-menu {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  border-color: rgba(120, 130, 140, 0.53);
}

.topbar .dropdown-menu .dropdown-item {
  padding: 7px 1.5rem;
}

.topbar ul.dropdown-user {
  padding: 0px;
  width: 270px;
}

.topbar ul.dropdown-user li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.topbar ul.dropdown-user li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: rgba(120, 130, 140, 0.53);
}

.topbar ul.dropdown-user li .dw-user-box {
  padding: 10px 15px;
}

.topbar ul.dropdown-user li .dw-user-box .u-img {
  width: 80px;
  display: inline-block;
  vertical-align: top;
}

.topbar ul.dropdown-user li .dw-user-box .u-img img {
  width: 100%;
  border-radius: 5px;
}

.topbar ul.dropdown-user li .dw-user-box .u-text {
  max-width: 80%;
  display: inline-block;
  padding-left: 10px;
}

.topbar ul.dropdown-user li .dw-user-box .u-text h4 {
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topbar ul.dropdown-user li .dw-user-box .u-text p {
  margin-bottom: 2px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topbar ul.dropdown-user li .dw-user-box .u-text .btn-rounded {
  float: left;
  width: auto;
}

.topbar ul.dropdown-user li .dw-user-box .u-text .btn {
  color: #ffffff;
  padding: 5px 10px;
  display: inline-block;
}

.topbar ul.dropdown-user li .dw-user-box .u-text .btn:hover {
  background: #e6294b;
}

.topbar ul.dropdown-user li a {
  padding: 9px 15px;
  display: block;
  color: #4d6074;
}

.topbar ul.dropdown-user li a:hover {
  background: #f2f7f8;
  color: #003c69;
  text-decoration: none;
}

.mini-sidebar .top-navbar .navbar-header {
  width: 60px;
  text-align: center;
}

.logo-center .top-navbar .navbar-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

/*******************
 Breadcrumb and page title
*******************/

.page-titles .breadcrumb {
  padding: 0px;
  background: transparent;
}

.page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\e649";
  font-family: themify;
  color: #a6b7bf;
  font-size: 14px;
}

/*******************
 Right side toggle
*******************/

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.right-side-toggle {
  position: relative;
}

.right-side-toggle i {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 1s;
  transition-property: transform;
  transition-duration: 1s;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotate;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  top: 9px;
  left: 10px;
}

.right-sidebar {
  position: fixed;
  right: -240px;
  width: 240px;
  display: none;
  z-index: 1100;
  background: #ffffff;
  top: 0px;
  padding-bottom: 20px;
  height: 100%;
  box-shadow: 5px 1px 40px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.right-sidebar .rpanel-title {
  display: block;
  padding: 24px 20px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  background: #003c69;
}

.right-sidebar .rpanel-title span {
  float: right;
  cursor: pointer;
  font-size: 11px;
}

.right-sidebar .rpanel-title span:hover {
  color: #ffffff;
}

.right-sidebar .r-panel-body {
  padding: 20px;
}

.right-sidebar .r-panel-body ul {
  margin: 0px;
  padding: 0px;
}

.right-sidebar .r-panel-body ul li {
  list-style: none;
  padding: 5px 0;
}

.shw-rside {
  right: 0px;
  width: 240px;
  display: block;
}

.chatonline img {
  margin-right: 10px;
  float: left;
  width: 30px;
}

.chatonline li a {
  padding: 13px 0;
  float: left;
  width: 100%;
}

.chatonline li a span {
  color: #4d6074;
}

.chatonline li a span small {
  display: block;
  font-size: 10px;
}

/*******************
 Right side toggle
*******************/

ul#themecolors {
  display: block;
}

ul#themecolors li {
  display: inline-block;
}

ul#themecolors li:first-child {
  display: block;
}

ul#themecolors li a {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin: 5px;
  color: transparent;
  position: relative;
}

ul#themecolors li a.working:before {
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 18px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  text-align: center;
}

.default-theme {
  background: #90a4ae;
}

.green-theme {
  background: #55ce63;
}

.yellow-theme {
  background: #ffbc34;
}

.red-theme {
  background: #f62d51;
}

.blue-theme {
  background: #009efb;
}

.purple-theme {
  background: #7460ee;
}

.megna-theme {
  background: #01c0c8;
}

.default-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #90a4ae 23%, #90a4ae 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #90a4ae 23%, #90a4ae 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #263238 0%, #263238 23%, #90a4ae 23%, #90a4ae 99%);
}

.green-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #55ce63 23%, #55ce63 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #00c292 23%, #55ce63 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #263238 0%, #263238 23%, #55ce63 23%, #55ce63 99%);
}

.yellow-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #f62d51 23%, #f62d51 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #f62d51 23%, #f62d51 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #263238 0%, #263238 23%, #f62d51 23%, #f62d51 99%);
}

.blue-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #009efb 23%, #009efb 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #009efb 23%, #009efb 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #263238 0%, #263238 23%, #009efb 23%, #009efb 99%);
}

.purple-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
}

.megna-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #01c0c8 23%, #01c0c8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #01c0c8 23%, #01c0c8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #263238 0%, #263238 23%, #01c0c8 23%, #01c0c8 99%);
}

.red-dark-theme {
  background: #263238;
  /* Old browsers */
  background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #f62d51 23%, #f62d51 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #f62d51 23%, #f62d51 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #263238 0%, #263238 23%, #f62d51 23%, #f62d51 99%);
}

/*******************
 page title
*******************/

.page-titles {
  padding-bottom: 20px;
}

/*******************
 Footer
*******************/

.footer {
  bottom: 0;
  color: #4d6074;
  left: 0px;
  padding: 17px 15px;
  position: absolute;
  right: 0;
  border-top: 1px solid rgba(120, 130, 140, 0.53);
  background: #ffffff;
}

/*******************
 Card title
*******************/

.card {
  margin-bottom: 30px;
}

.card .card-subtitle {
  font-weight: 300;
  margin-bottom: 15px;
  color: #90a4ae;
}

.card-inverse .card-blockquote .blockquote-footer,
.card-inverse .card-link,
.card-inverse .card-subtitle,
.card-inverse .card-text {
  color: rgba(255, 255, 255, 0.65);
}

.card-success {
  background: #55ce63;
  border-color: #55ce63;
}

.card-danger {
  background: #f62d51;
  border-color: #f62d51;
}

.card-warning {
  background: #ffbc34;
  border-color: #ffbc34;
}

.card-info {
  background: #009efb;
  border-color: #009efb;
}

.card-primary {
  background: #7460ee;
  border-color: #7460ee;
}

.card-dark {
  background: #2f3d4a;
  border-color: #2f3d4a;
}

.card-megna {
  background: #01c0c8;
  border-color: #01c0c8;
}

/*============================================================== 
 Buttons page
 ============================================================== */

.button-group .btn {
  margin-bottom: 5px;
  margin-right: 5px;
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px;
}

.btn .text-active {
  display: none;
}

.btn.active .text-active {
  display: inline-block;
}

.btn.active .text {
  display: none;
}

/*============================================================== 
 Cards page
 ============================================================== */

.card-actions {
  float: right;
}

.card-actions a {
  cursor: pointer;
  color: #4d6074;
  opacity: 0.7;
  padding-left: 7px;
  font-size: 13px;
}

.card-actions a:hover {
  opacity: 1;
}

.card-columns .card {
  margin-bottom: 20px;
}

.collapsing {
  -webkit-transition: height .08s ease;
  transition: height .08s ease;
}

.card-info {
  background: #009efb;
  border-color: #009efb;
}

.card-primary {
  background: #7460ee;
  border-color: #7460ee;
}

.card-outline-info {
  border-color: #009efb;
}

.card-outline-info .card-header {
  background: #009efb;
  border-color: #009efb;
}

.card-outline-inverse {
  border-color: #2f3d4a;
}

.card-outline-inverse .card-header {
  background: #2f3d4a;
  border-color: #2f3d4a;
}

.card-outline-warning {
  border-color: #ffbc34;
}

.card-outline-warning .card-header {
  background: #ffbc34;
  border-color: #ffbc34;
}

.card-outline-success {
  border-color: #55ce63;
}

.card-outline-success .card-header {
  background: #55ce63;
  border-color: #55ce63;
}

.card-outline-danger {
  border-color: #f62d51;
}

.card-outline-danger .card-header {
  background: #f62d51;
  border-color: #f62d51;
}

.card-outline-primary {
  border-color: #7460ee;
}

.card-outline-primary .card-header {
  background: #7460ee;
  border-color: #7460ee;
}

/*============================================================== 
Breadcrumb
 ============================================================== */

.bc-colored .breadcrumb-item,
.bc-colored .breadcrumb-item a {
  color: #ffffff;
}

.bc-colored .breadcrumb-item.active,
.bc-colored .breadcrumb-item a.active {
  opacity: 0.7;
}

.bc-colored .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.4);
}

.breadcrumb {
  margin-bottom: 0px;
}

/*============================================================== 
 Ui-bootstrap
 ============================================================== */

ul.list-icons {
  margin: 0px;
  padding: 0px;
}

ul.list-icons li {
  list-style: none;
  line-height: 30px;
  margin: 5px 0;
  transition: 0.2s ease-in;
}

ul.list-icons li a {
  color: #4d6074;
}

ul.list-icons li a:hover {
  color: #003c69;
}

ul.list-icons li i {
  font-size: 13px;
  padding-right: 8px;
}

ul.list-inline li {
  display: inline-block;
  padding: 0 8px;
}

ul.two-part {
  margin: 0px;
}

ul.two-part li {
  width: 48.8%;
}

/*Accordion*/

html body .accordion .card {
  margin-bottom: 0px;
}

/*============================================================== 
 flot chart
 ============================================================== */

.flot-chart {
  display: block;
  height: 400px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

html body .jqstooltip,
html body .flotTip {
  width: auto !important;
  height: auto !important;
  background: #263238;
  color: #ffffff;
  padding: 5px 10px;
}

/*============================================================== 
Easy pie chart 
 ============================================================== */

.chart {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}

.pie-chart > span {
  left: 0;
  margin-top: -2px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.chart > span > img {
  left: 0;
  margin-top: -2px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  width: 60%;
  height: 60%;
  transform: translateY(-50%);
  margin: 0 auto;
}

.percent {
  display: inline-block;
  line-height: 100px;
  z-index: 2;
  font-weight: 600;
  font-size: 18px;
  color: #263238;
}

.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}

/*============================================================== 
Dashboard1 chart
 ============================================================== */

.total-revenue,
.income-year,
.total-revenue2 {
  position: relative;
}

.total-revenue .chartist-tooltip,
.income-year .chartist-tooltip,
.total-revenue2 .chartist-tooltip {
  border-radius: 60px;
}

.total-revenue .ct-series-a .ct-line,
.income-year .ct-series-a .ct-line,
.total-revenue2 .ct-series-a .ct-line {
  stroke: #009efb;
  stroke-width: 2px;
}

.total-revenue .ct-series-a .ct-point,
.income-year .ct-series-a .ct-point,
.total-revenue2 .ct-series-a .ct-point {
  stroke: #009efb;
  stroke-width: 7px;
}

.total-revenue .ct-series-b .ct-line,
.income-year .ct-series-b .ct-line,
.total-revenue2 .ct-series-b .ct-line {
  stroke: #55ce63;
  stroke-width: 2px;
}

.total-revenue .ct-series-b .ct-point,
.income-year .ct-series-b .ct-point,
.total-revenue2 .ct-series-b .ct-point {
  stroke: #55ce63;
  stroke-width: 7px;
}

.total-revenue .ct-series-a .ct-area,
.total-revenue .ct-series-b .ct-area,
.income-year .ct-series-a .ct-area,
.income-year .ct-series-b .ct-area,
.total-revenue2 .ct-series-a .ct-area,
.total-revenue2 .ct-series-b .ct-area {
  fill: transparent;
}

.income-year .ct-series-a .ct-bar {
  stroke: #009efb;
}

.income-year .ct-series-b .ct-bar {
  stroke: #55ce63;
}

#sales-donute {
  position: relative;
}

#sales-donute path,
#sales-donute fill {
  stroke-width: 0px;
}

.round-overlap {
  width: 150px;
  border: 2px solid rgba(120, 130, 140, 0.53);
  height: 150px;
  position: absolute;
  border-radius: 100%;
  font-size: 35px;
  text-align: center;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  top: 25%;
}

.round-overlap i {
  line-height: 150px;
}

.stylish-table thead th {
  font-weight: 400;
  color: #90a4ae;
  border: 0px;
  border-bottom: 1px;
}

.stylish-table tbody tr {
  border-left: 4px solid #ffffff;
}

.stylish-table tbody tr:hover,
.stylish-table tbody tr.active {
  border-left: 4px solid #003c69;
}

.stylish-table tbody td {
  vertical-align: middle;
}

.stylish-table tbody td h6 {
  font-weight: 500;
  margin-bottom: 0px;
  white-space: nowrap;
}

.stylish-table tbody td small {
  line-height: 12px;
  white-space: nowrap;
}

/*============================================================== 
Dashboard2 chart
 ============================================================== */

.total-revenue2 .ct-series-a .ct-area {
  fill: #e8fdeb;
}

.total-revenue2 .ct-series-b .ct-area {
  fill: #e8fdeb;
}

/*============================================================== 
Dashboard3 chart
 ============================================================== */

.total-sales {
  position: relative;
}

.total-sales .chartist-tooltip {
  background: #55ce63;
}

.total-sales .ct-series-a .ct-bar {
  stroke: #0f8edd;
}

.total-sales .ct-series-b .ct-bar {
  stroke: #11a0f8;
}

.total-sales .ct-series-c .ct-bar {
  stroke: #51bdff;
}

.ct-chart {
  position: relative;
}

.ct-chart .ct-series-a .ct-slice-donut {
  stroke: #55ce63;
}

.ct-chart .ct-series-b .ct-slice-donut {
  stroke: #f2f7f8;
}

.ct-chart .ct-series-c .ct-slice-donut {
  stroke: #009efb;
}

#visitfromworld path.jvectormap-region.jvectormap-element {
  stroke-width: 1px;
  stroke: #90a4ae;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  background: #90a4ae;
}

.browser td {
  vertical-align: middle;
  padding-left: 0px;
}

#calendar .fc-today-button {
  display: none;
}

/*============================================================== 
Dashboard4 chart
 ============================================================== */

.total-revenue4 {
  position: relative;
}

.total-revenue4 .ct-series-a .ct-line {
  stroke: #009efb;
  stroke-width: 1px;
}

.total-revenue4 .ct-series-a .ct-point {
  stroke: #009efb;
  stroke-width: 5px;
}

.total-revenue4 .ct-series-b .ct-line {
  stroke: #55ce63;
  stroke-width: 1px;
}

.total-revenue4 .ct-series-b .ct-point {
  stroke: #55ce63;
  stroke-width: 5px;
}

.total-revenue4 .ct-series-a .ct-area {
  fill: #009efb;
  fill-opacity: 0.2;
}

.total-revenue4 .ct-series-b .ct-area {
  fill: #55ce63;
  fill-opacity: 0.2;
}

/*============================================================== 
Widget-data
 ============================================================== */

.sparkchart {
  margin-bottom: -2px;
}

/*============================================================== 
File upload
 ============================================================== */

.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
  border-radius: 0px;
}

.fileinput .input-group-addon {
  border-right: 1px solid rgba(120, 130, 140, 0.53);
}

.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

/*============================================================== 
widget-chart-page product review
 ============================================================== */

.product-review {
  margin: 0px;
  padding: 25px;
}

.product-review li {
  display: block;
  padding: 20px 0;
  list-style: none;
}

.product-review li .font,
.product-review li span {
  display: inline-block;
  margin-left: 10px;
}

.social-profile {
  text-align: center;
  background: rgba(7, 10, 43, 0.8);
}

.profile-tab li a.nav-link,
.customtab li a.nav-link {
  border: 0px;
  padding: 15px 20px;
  color: #4d6074;
}

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
  border-bottom: 2px solid #003c69;
  color: #003c69;
}

.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
  color: #003c69;
}

/*============================================================== 
Form addons page
============================================================== */

/*Bootstrap select*/

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select .dropdown-menu li a {
  display: block;
  padding: 7px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #4d6074;
  white-space: nowrap;
}

.bootstrap-select .dropdown-menu li a:hover,
.bootstrap-select .dropdown-menu li a:focus {
  color: #003c69;
  background: #f2f7f8;
}

.bootstrap-select .show > .dropdown-menu {
  display: block;
}

.bootstrap-select .btn-secondary {
  background-color: #ffffff;
}

.bootstrap-select .btn-secondary:hover {
  color: #4d6074;
}

.select2-container--default .select2-selection--single {
  border-color: #d9d9d9;
  height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 33px;
}

.input-form .btn {
  padding: 7px 12px;
}

/*============================================================== 
Form Material page
 ============================================================== */

/*Material inputs*/

.form-material .form-group {
  overflow: hidden;
}

.form-material .form-control {
  background-color: rgba(0, 0, 0, 0);
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
  height: calc(2.25rem + 2px);
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: linear-gradient(#003c69, #003c69), linear-gradient(#d9d9d9, #d9d9d9);
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none;
}

.form-material .form-control.focus,
.form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0 none;
  transition-duration: 0.3s;
}

.form-control-line .form-group {
  overflow: hidden;
}

.form-control-line .form-control {
  border: 0px;
  border-radius: 0px;
  padding-left: 0px;
  border-bottom: 1px solid #d9d9d9;
}

.form-control-line .form-control:focus {
  border-bottom: 1px solid #003c69;
}

/*******************/

/*Floating Label*/

/*******************/

.floating-labels .form-group {
  position: relative;
}

.floating-labels .form-control {
  padding: 10px 10px 10px 0;
  display: block;
  border: none;
  font-family: "Rubik", sans-serif;
  border-radius: 0px;
  border-bottom: 1px solid #d9d9d9;
}

.floating-labels .form-control:focus {
  box-shadow: none;
}

.floating-labels select.form-control > option {
  font-size: 14px;
}

.floating-labels .has-error .form-control {
  border-bottom: 1px solid #f62d51;
}

.floating-labels .has-warning .form-control {
  border-bottom: 1px solid #ffbc34;
}

.floating-labels .has-success .form-control {
  border-bottom: 1px solid #55ce63;
}

.floating-labels .form-control:focus {
  outline: none;
  border: none;
}

.floating-labels label {
  color: #4d6074;
  position: absolute;
  cursor: auto;
  top: 5px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-labels .form-control:focus ~ label,
.floating-labels .form-control:valid ~ label {
  top: -20px;
  font-size: 12px;
  color: #263238;
}

.floating-labels .bar {
  position: relative;
  display: block;
}

.floating-labels .bar:before,
.floating-labels .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #003c69;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-labels .bar:before {
  left: 50%;
}

.floating-labels .bar:after {
  right: 50%;
}

.floating-labels .form-control:focus ~ .bar:before,
.floating-labels .form-control:focus ~ .bar:after {
  width: 50%;
}

.floating-labels .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.floating-labels .input-lg ~ label,
.floating-labels .input-lg {
  font-size: 24px;
}

.floating-labels .input-sm ~ label,
.floating-labels .input-sm {
  font-size: 16px;
}

.has-warning .bar:before,
.has-warning .bar:after {
  background: #ffbc34;
}

.has-success .bar:before,
.has-success .bar:after {
  background: #55ce63;
}

.has-error .bar:before,
.has-error .bar:after {
  background: #f62d51;
}

.has-warning .form-control:focus ~ label,
.has-warning .form-control:valid ~ label {
  color: #ffbc34;
}

.has-success .form-control:focus ~ label,
.has-success .form-control:valid ~ label {
  color: #55ce63;
}

.has-error .form-control:focus ~ label,
.has-error .form-control:valid ~ label {
  color: #f62d51;
}

.has-feedback label ~ .t-0 {
  top: 0;
}

.form-group.error input,
.form-group.error select,
.form-group.error textarea {
  border: 1px solid #f62d51;
}

.form-group.validate input,
.form-group.validate select,
.form-group.validate textarea {
  border: 1px solid #55ce63;
}

.form-group.error .help-block ul {
  padding: 0px;
  color: #f62d51;
}

.form-group.error .help-block ul li {
  list-style: none;
}

.form-group.issue .help-block ul {
  padding: 0px;
  color: #ffbc34;
}

.form-group.issue .help-block ul li {
  list-style: none;
}

/*******************
Pagination
******************/

.pagination-circle li.active a {
  background: #55ce63;
}

.pagination-circle li a {
  width: 40px;
  height: 40px;
  background: #f2f7f8;
  border: 0px;
  text-align: center;
  border-radius: 100%;
}

.pagination-circle li a:first-child,
.pagination-circle li a:last-child {
  border-radius: 100%;
}

.pagination-circle li a:hover {
  background: #55ce63;
  color: #ffffff;
}

.pagination-circle li.disabled a {
  background: #f2f7f8;
  color: rgba(120, 130, 140, 0.53);
}

/*******************
Form Dropzone
******************/

.dropzone {
  border: 1px dashed #d9d9d9;
}

.dropzone .dz-message {
  padding: 5% 0;
  margin: 0px;
}

/*******************
Form Pickers
******************/

.asColorPicker-dropdown {
  max-width: 260px;
}

.asColorPicker-trigger {
  position: absolute;
  top: 0;
  right: -35px;
  height: 38px;
  width: 37px;
  border: 0;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  top: 5px;
  right: 10px;
  text-decoration: none;
}

table th {
  font-weight: 400;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #003c69;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background: #003c69;
  color: #ffffff;
}

.datepicker td,
.datepicker th {
  padding: 5px 10px;
}

/*******************
Form icheck
******************/

.icolors,
.icheck-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.icolors > li {
  padding: 0;
  margin: 2px;
  float: left;
  display: inline-block;
  height: 30px;
  width: 30px;
  background: #263238;
  text-align: center;
}

.icolors > li.active:after {
  content: "\2713 ";
  color: #ffffff;
  line-height: 30px;
}

.icolors > li:first-child {
  margin-left: 0;
}

.icolors > li.orange {
  background: #f62d51;
}

.icolors > li.yellow {
  background: #ffbc34;
}

.icolors > li.info {
  background: #009efb;
}

.icolors > li.green {
  background: #55ce63;
}

.icolors > li.red {
  background: #fb3a3a;
}

.icolors > li.purple {
  background: #7460ee;
}

.icolors > li.blue {
  background: #02bec9;
}

.icheck-list {
  float: left;
  padding-right: 50px;
  padding-top: 10px;
}

.icheck-list li {
  padding-bottom: 5px;
}

.icheck-list li label {
  padding-left: 10px;
}

/*******************
Form summernote
******************/

.note-popover,
.note-icon-caret {
  display: none;
}

.note-editor.note-frame {
  border: 1px solid #d9d9d9;
}

.note-editor.note-frame .panel-heading {
  padding: 6px 10px 10px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
}

.label {
  display: inline-block;
}

/*******************
Table-Layout
******************/

.table thead th,
.table th {
  border: 0px;
}

.color-table.primary-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #f2f7f8;
}

.color-table.success-table thead th {
  background-color: #55ce63;
  color: #ffffff;
}

.color-table.info-table thead th {
  background-color: #009efb;
  color: #ffffff;
}

.color-table.warning-table thead th {
  background-color: #ffbc34;
  color: #ffffff;
}

.color-table.danger-table thead th {
  background-color: #f62d51;
  color: #ffffff;
}

.color-table.inverse-table thead th {
  background-color: #2f3d4a;
  color: #ffffff;
}

.color-table.dark-table thead th {
  background-color: #263238;
  color: #ffffff;
}

.color-table.red-table thead th {
  background-color: #fb3a3a;
  color: #ffffff;
}

.color-table.purple-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.color-table.muted-table thead th {
  background-color: #90a4ae;
  color: #ffffff;
}

.color-bordered-table.primary-bordered-table {
  border: 2px solid #7460ee;
}

.color-bordered-table.primary-bordered-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.color-bordered-table.success-bordered-table {
  border: 2px solid #55ce63;
}

.color-bordered-table.success-bordered-table thead th {
  background-color: #55ce63;
  color: #ffffff;
}

.color-bordered-table.info-bordered-table {
  border: 2px solid #009efb;
}

.color-bordered-table.info-bordered-table thead th {
  background-color: #009efb;
  color: #ffffff;
}

.color-bordered-table.warning-bordered-table {
  border: 2px solid #ffbc34;
}

.color-bordered-table.warning-bordered-table thead th {
  background-color: #ffbc34;
  color: #ffffff;
}

.color-bordered-table.danger-bordered-table {
  border: 2px solid #f62d51;
}

.color-bordered-table.danger-bordered-table thead th {
  background-color: #f62d51;
  color: #ffffff;
}

.color-bordered-table.inverse-bordered-table {
  border: 2px solid #2f3d4a;
}

.color-bordered-table.inverse-bordered-table thead th {
  background-color: #2f3d4a;
  color: #ffffff;
}

.color-bordered-table.dark-bordered-table {
  border: 2px solid #263238;
}

.color-bordered-table.dark-bordered-table thead th {
  background-color: #263238;
  color: #ffffff;
}

.color-bordered-table.red-bordered-table {
  border: 2px solid #fb3a3a;
}

.color-bordered-table.red-bordered-table thead th {
  background-color: #fb3a3a;
  color: #ffffff;
}

.color-bordered-table.purple-bordered-table {
  border: 2px solid #7460ee;
}

.color-bordered-table.purple-bordered-table thead th {
  background-color: #7460ee;
  color: #ffffff;
}

.color-bordered-table.muted-bordered-table {
  border: 2px solid #90a4ae;
}

.color-bordered-table.muted-bordered-table thead th {
  background-color: #90a4ae;
  color: #ffffff;
}

.full-color-table.full-primary-table {
  background-color: #f1effd;
}

.full-color-table.full-primary-table thead th {
  background-color: #7460ee;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-primary-table tbody td {
  border: 0;
}

.full-color-table.full-primary-table tr:hover {
  background-color: #7460ee;
  color: #ffffff;
}

.full-color-table.full-success-table {
  background-color: #e8fdeb;
}

.full-color-table.full-success-table thead th {
  background-color: #55ce63;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-success-table tbody td {
  border: 0;
}

.full-color-table.full-success-table tr:hover {
  background-color: #55ce63;
  color: #ffffff;
}

.full-color-table.full-info-table {
  background-color: #cfecfe;
}

.full-color-table.full-info-table thead th {
  background-color: #009efb;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-info-table tbody td {
  border: 0;
}

.full-color-table.full-info-table tr:hover {
  background-color: #009efb;
  color: #ffffff;
}

.full-color-table.full-warning-table {
  background-color: #fff8ec;
}

.full-color-table.full-warning-table thead th {
  background-color: #ffbc34;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-warning-table tbody td {
  border: 0;
}

.full-color-table.full-warning-table tr:hover {
  background-color: #ffbc34;
  color: #ffffff;
}

.full-color-table.full-danger-table {
  background-color: #f9e7eb;
}

.full-color-table.full-danger-table thead th {
  background-color: #f62d51;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-danger-table tbody td {
  border: 0;
}

.full-color-table.full-danger-table tr:hover {
  background-color: #f62d51;
  color: #ffffff;
}

.full-color-table.full-inverse-table {
  background-color: #f6f6f6;
}

.full-color-table.full-inverse-table thead th {
  background-color: #2f3d4a;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-inverse-table tbody td {
  border: 0;
}

.full-color-table.full-inverse-table tr:hover {
  background-color: #2f3d4a;
  color: #ffffff;
}

.full-color-table.full-dark-table {
  background-color: rgba(43, 43, 43, 0.8);
}

.full-color-table.full-dark-table thead th {
  background-color: #263238;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-dark-table tbody td {
  border: 0;
  color: #ffffff;
}

.full-color-table.full-dark-table tr:hover {
  background-color: #263238;
  color: #ffffff;
}

.full-color-table.full-red-table {
  background-color: #f9e7eb;
}

.full-color-table.full-red-table thead th {
  background-color: #fb3a3a;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-red-table tbody td {
  border: 0;
}

.full-color-table.full-red-table tr:hover {
  background-color: #fb3a3a;
  color: #ffffff;
}

.full-color-table.full-purple-table {
  background-color: #f1effd;
}

.full-color-table.full-purple-table thead th {
  background-color: #7460ee;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-purple-table tbody td {
  border: 0;
}

.full-color-table.full-purple-table tr:hover {
  background-color: #7460ee;
  color: #ffffff;
}

.full-color-table.full-muted-table {
  background-color: rgba(152, 166, 173, 0.2);
}

.full-color-table.full-muted-table thead th {
  background-color: #90a4ae;
  border: 0;
  color: #ffffff;
}

.full-color-table.full-muted-table tbody td {
  border: 0;
}

.full-color-table.full-muted-table tr:hover {
  background-color: #90a4ae;
  color: #ffffff;
}

/*******************
Table-Data Table
******************/

.dt-buttons {
  display: inline-block;
  padding-top: 5px;
}

.dt-buttons .dt-button {
  padding: 5px 15px;
  border-radius: 4px;
  background: #003c69;
  color: #ffffff;
  margin-right: 3px;
}

.dt-buttons .dt-button:hover {
  background: #2f3d4a;
}

.dataTables_info,
.dataTables_length {
  display: inline-block;
}

.dataTables_filter {
  float: right;
}

.dataTables_filter input {
  border: 1px solid #d9d9d9;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  *cursor: hand;
  color: #4d6074;
  border: 1px solid transparent;
  border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #ffffff;
  border: 1px solid #009efb;
  background-color: #009efb;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #4d6074;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white;
  border: 1px solid #7460ee;
  background-color: #7460ee;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #4d6074;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

/*******************
Table- responsive
******************/

.tablesaw-bar .btn-group label {
  color: #4d6074 !important;
}

/*******************
Table- editable table
******************/

.dt-bootstrap {
  display: block;
}

.paging_simple_numbers .pagination .paginate_button {
  padding: 0px;
  background: #ffffff;
}

.paging_simple_numbers .pagination .paginate_button:hover {
  background: #ffffff;
}

.paging_simple_numbers .pagination .paginate_button a {
  padding: 5px 10px;
  border-radius: 4px;
  border: 0px;
}

.paging_simple_numbers .pagination .paginate_button.active a,
.paging_simple_numbers .pagination .paginate_button:hover a {
  background: #009efb;
  color: #ffffff;
}

/*******************
Table- Footable
******************/

/*******************
Footable Table
******************/

#demo-foo-accordion .open > .dropdown-menu {
  display: block;
}

#demo-foo-accordion2 .open > .dropdown-menu {
  display: block;
}

#footable-addrow .open > .dropdown-menu {
  display: block;
}

table.footable thead tr.footable-filtering th ul.dropdown-menu li a.checkbox {
  padding-left: 0;
}

table.footable thead tr.footable-filtering th ul.dropdown-menu li a.checkbox input[type=checkbox] {
  position: relative;
  margin-right: 10px;
  margin-left: -20px;
  left: 0;
  opacity: 1;
}

table.footable thead tr.footable-filtering th ul.dropdown-menu li .checkbox label::before {
  display: none;
}

.footable .pagination li a {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0275d8;
  background-color: #ffffff;
  border: 1px solid #ddd;
}

.footable .pagination li.active a {
  z-index: 2;
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.footable .pagination li.disabled a {
  color: #636c72;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #ffffff;
  border-color: #ddd;
}

.footable .pagination li:first-child a {
  margin-left: 0;
  border-bottom-left-radius: .25rem;
  border-top-left-radius: .25rem;
}

/*******************
Icon list fontawesom
******************/

.icon-list-demo div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  color: #4d6074;
}

.icon-list-demo div:hover {
  color: #263238;
}

.icon-list-demo div p {
  margin: 10px 0;
  padding: 5px 0;
}

.icon-list-demo i {
  -webkit-transition: all 0.2s;
  -webkit-transition: font-size .2s;
  display: inline-block;
  font-size: 18px;
  margin: 0 15px 0 10px;
  text-align: left;
  transition: all 0.2s;
  transition: font-size .2s;
  vertical-align: middle;
  transition: all 0.3s ease 0s;
}

.icon-list-demo .col-md-4,
.icon-list-demo .col-3 {
  border-radius: 4px;
}

.icon-list-demo .col-md-4:hover,
.icon-list-demo .col-3:hover {
  background-color: #ebf3f5;
}

.icon-list-demo .div:hover i {
  font-size: 2em;
}

/*******************
Icon list material icon
******************/

.material-icon-list-demo .mdi {
  font-size: 21px;
}

/*******************
Gridstack Dragable portlet
******************/

.grid-stack-item-content {
  background: #fff;
  color: #2b2b2b;
  text-align: center;
  font-size: 20px;
}

.grid-stack > .grid-stack-item > .grid-stack-item-content {
  border: 1px solid rgba(120, 130, 140, 0.53);
}

/*******************
Bootstrap Switch
******************/

.bootstrap-switch,
.bootstrap-switch .bootstrap-switch-container {
  border-radius: 2px;
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  color: #ffffff;
  background: #7460ee;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
  color: #ffffff;
  background: #009efb;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  color: #ffffff;
  background: #55ce63;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
  color: #ffffff;
  background: #ffbc34;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
  color: #ffffff;
  background: #f62d51;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  color: #263238;
  background: #f2f7f8;
}

.onoffswitch {
  position: relative;
  width: 90px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #55ce63;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 27px;
  background-color: #55ce63;
  color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 24px;
  background-color: #EEEEEE;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 23px;
  margin: 6px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px;
  border: 2px solid #55ce63;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/*******************
Date paginator page
******************/

.dp-selected[style] {
  background-color: #003c69 !important;
}

.datepaginator-sm .pagination li a,
.datepaginator-lg .pagination li a,
.datepaginator .pagination li a {
  padding: 0 5px;
  height: 60px;
  border: 1px solid rgba(120, 130, 140, 0.53);
  float: left;
  position: relative;
}

/*******************
sweet alert page
******************/

.model_img {
  cursor: pointer;
}

/*******************
Grid page
******************/

.show-grid {
  margin-bottom: 10px;
  padding: 0 15px;
}

.show-grid [class^=col-] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #d9d9d9;
  background-color: #f2f7f8;
}

/*******************
Vertical tabs
******************/

.vtabs {
  display: table;
}

.vtabs .tabs-vertical {
  width: 150px;
  border-bottom: 0px;
  border-right: 1px solid rgba(120, 130, 140, 0.53);
  display: table-cell;
  vertical-align: top;
}

.vtabs .tabs-vertical li .nav-link {
  color: #263238;
  margin-bottom: 10px;
  border: 0px;
  border-radius: 4px 0 0 4px;
}

.vtabs .tab-content {
  display: table-cell;
  padding: 20px;
  vertical-align: top;
}

.tabs-vertical li .nav-link.active,
.tabs-vertical li .nav-link:hover,
.tabs-vertical li .nav-link.active:focus {
  background: #003c69;
  border: 0px;
  color: #ffffff;
}

/*Custom vertical tab*/

.customvtab .tabs-vertical li .nav-link.active,
.customvtab .tabs-vertical li .nav-link:hover,
.customvtab .tabs-vertical li .nav-link:focus {
  background: #ffffff;
  border: 0px;
  border-right: 2px solid #003c69;
  margin-right: -1px;
  color: #003c69;
}

.tabcontent-border {
  border: 1px solid #ddd;
  border-top: 0px;
}

.customtab2 li a.nav-link {
  border: 0px;
  margin-right: 3px;
  color: #4d6074;
}

.customtab2 li a.nav-link.active {
  background: #003c69;
  color: #ffffff;
}

.customtab2 li a.nav-link:hover {
  color: #ffffff;
  background: #003c69;
}

/*******************
Progress bar
******************/

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-vertical {
  min-height: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  transform: rotate(180deg);
}

.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: myanimation;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: myanimation;
  transition: 5s all;
}

@-webkit-keyframes myanimation {
  from {
    width: 0;
  }
}

@keyframes myanimation {
  from {
    width: 0;
  }
}

/*******************
Notification page Jquery toaster
******************/

.jq-icon-info {
  background-color: #009efb;
  color: #ffffff;
}

.jq-icon-success {
  background-color: #55ce63;
  color: #ffffff;
}

.jq-icon-error {
  background-color: #f62d51;
  color: #ffffff;
}

.jq-icon-warning {
  background-color: #ffbc34;
  color: #ffffff;
}

.alert-rounded {
  border-radius: 60px;
}

/*******************
list and media
******************/

.list-group a.list-group-item:hover {
  background: #f2f7f8;
}

.list-group-item.active,
.list-group .list-group-item.active:hover {
  background: #003c69;
  border-color: #003c69;
}

.list-group-item.disabled {
  color: #90a4ae;
  background: #f2f7f8;
}

.media {
  border: 1px solid rgba(120, 130, 140, 0.53);
  margin-bottom: 10px;
  padding: 15px;
}

/*******************
User card page
******************/

.el-element-overlay .white-box {
  padding: 0px;
}

.el-element-overlay .el-card-item {
  position: relative;
  padding-bottom: 25px;
}

.el-element-overlay .el-card-item .el-card-avatar {
  margin-bottom: 15px;
}

.el-element-overlay .el-card-item .el-card-content {
  text-align: center;
}

.el-element-overlay .el-card-item .el-card-content h3 {
  margin: 0px;
}

.el-element-overlay .el-card-item .el-card-content a {
  color: #4d6074;
}

.el-element-overlay .el-card-item .el-card-content a:hover {
  color: #003c69;
}

.el-element-overlay .el-card-item .el-overlay-1 {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.el-element-overlay .el-card-item .el-overlay-1 img {
  display: block;
  position: relative;
  -webkit-transition: all .4s linear;
  transition: all .4s linear;
  width: 100%;
  height: auto;
}

.el-element-overlay .el-card-item .el-overlay-1:hover img {
  -ms-transform: scale(1.2) translateZ(0);
  -webkit-transform: scale(1.2) translateZ(0);
  /* transform: scale(1.2) translateZ(0); */
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info {
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: #ffffff;
  background-color: transparent;
  filter: alpha(opacity=0);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  padding: 0;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%) translateZ(0);
  -webkit-transform: translateY(-50%) translateZ(0);
  -ms-transform: translateY(-50%) translateZ(0);
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info > li {
  list-style: none;
  display: inline-block;
  margin: 0 3px;
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info > li a {
  border-color: #ffffff;
  color: #ffffff;
  padding: 12px 15px 10px;
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info > li a:hover {
  background: #003c69;
  border-color: #003c69;
}

.el-element-overlay .el-card-item .el-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .el-overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.el-element-overlay .el-card-item .el-overlay-1 .scrl-dwn {
  top: -100%;
}

.el-element-overlay .el-card-item .el-overlay-1 .scrl-up {
  top: 100%;
  height: 0px;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-dwn {
  top: 0px;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-up {
  top: 0px;
  height: 100%;
}

/*******************
Timeline page
******************/

.timeline {
  position: relative;
  padding: 20px 0 20px;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  margin-left: -1.5px;
  background-color: #f2f7f8;
}

.timeline > li {
  position: relative;
  margin-bottom: 20px;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid rgba(120, 130, 140, 0.53);
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

.timeline > li > .timeline-panel:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 26px;
  right: -8px;
  border-top: 8px solid transparent;
  border-right: 0 solid rgba(120, 130, 140, 0.53);
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(120, 130, 140, 0.53);
}

.timeline > li > .timeline-panel:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #ffffff;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #ffffff;
}

.timeline > li > .timeline-badge {
  z-index: 10;
  position: absolute;
  top: 16px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50% 50% 50% 50%;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: #fff;
  overflow: hidden;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}

.timeline-badge.primary {
  background-color: #7460ee;
}

.timeline-badge.success {
  background-color: #55ce63;
}

.timeline-badge.warning {
  background-color: #ffbc34;
}

.timeline-badge.danger {
  background-color: #f62d51;
}

.timeline-badge.info {
  background-color: #009efb;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

/*******************
Horizontal Timeline page
******************/

.cd-horizontal-timeline .events a {
  padding-bottom: 6px;
  color: #003c69;
}

.cd-horizontal-timeline .filling-line,
.cd-horizontal-timeline .events a.selected::after {
  background: #003c69;
}

.cd-horizontal-timeline .events a.selected::after {
  border-color: #003c69;
}

.myadmin-dd .dd-list .dd-item .dd-handle {
  background: #ffffff;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 8px 16px;
  height: auto;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  border-radius: 0;
}

.myadmin-dd-empty .dd-list .dd3-content {
  height: auto;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 8px 16px 8px 46px;
  background: #ffffff;
  font-weight: 400;
}

.myadmin-dd-empty .dd-list .dd3-handle {
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-bottom: 0;
  background: #ffffff;
  height: 36px;
  width: 36px;
}

.dd3-handle:before {
  color: #4d6074;
  top: 7px;
}

/*******************
ribbons page
******************/

.ribbon-wrapper,
.ribbon-wrapper-reverse,
.ribbon-wrapper-bottom,
.ribbon-wrapper-right-bottom {
  position: relative;
  padding: 50px 15px 15px 15px;
}

.ribbon-vwrapper {
  padding: 15px 15px 15px 50px;
  position: relative;
}

.ribbon-overflow {
  overflow: hidden;
}

.ribbon-vwrapper-reverse {
  padding: 15px 50px 15px 15px;
}

.ribbon-wrapper-bottom {
  padding: 15px 15px 50px 50px;
}

.ribbon-wrapper-right-bottom {
  padding: 15px 50px 50px 15px;
}

.ribbon-content {
  margin-bottom: 0px;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: -2px;
  color: #ffffff;
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border: 15px solid #263238;
  border-right: 10px solid transparent;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #263238;
  border-left: 10px solid transparent;
}

.ribbon-vertical-l,
.ribbon-vertical-r {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-r {
  left: auto;
  right: 12px;
}

.ribbon-bookmark.ribbon-vertical-l:before,
.ribbon-bookmark.ribbon-vertical-r:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #263238;
  border-bottom: 10px solid transparent;
}

.ribbon-badge {
  top: 15px;
  overflow: hidden;
  left: -90px;
  width: 100%;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-badge.ribbon-right {
  left: auto;
  right: -90px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: 15px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon-badge.ribbon-right.ribbon-bottom {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon-corner {
  top: 0;
  left: 0;
  background-color: transparent !important;
  padding: 6px 0 0 10px;
}

.ribbon-corner i {
  position: relative;
}

.ribbon-corner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 30px solid transparent;
  border-top-color: #003c69;
  border-left-color: #003c69;
}

.ribbon-corner.ribbon-right:before {
  right: 0;
  left: auto;
  border-right-color: #526069;
  border-left-color: transparent;
}

.ribbon-corner.ribbon-right {
  right: 0;
  left: auto;
  padding: 6px 10px 0 0;
}

.ribbon-corner.ribbon-bottom:before {
  top: auto;
  bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #526069;
}

.ribbon-corner.ribbon-bottom {
  bottom: 0;
  top: auto;
  padding: 0 10px 6px 10px;
}

.ribbon-custom {
  background: #003c69;
}

.ribbon-bookmark.ribbon-right.ribbon-custom:before {
  border-right-color: #003c69;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-custom:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-custom:before {
  border-right-color: #003c69;
  border-bottom-color: transparent;
}

.ribbon-primary {
  background: #7460ee;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #7460ee;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #7460ee;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-primary:before {
  border-right-color: #7460ee;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #7460ee;
  border-left-color: #7460ee;
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #7460ee;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #7460ee;
}

.ribbon-success {
  background: #55ce63;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #55ce63;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #55ce63;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-success:before {
  border-right-color: #55ce63;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #55ce63;
  border-left-color: #55ce63;
}

.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #55ce63;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #55ce63;
}

.ribbon-info {
  background: #009efb;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #009efb;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #009efb;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-info:before {
  border-right-color: #009efb;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #009efb;
  border-left-color: #009efb;
}

.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #009efb;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #009efb;
}

.ribbon-warning {
  background: #ffbc34;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #ffbc34;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #ffbc34;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-warning:before {
  border-right-color: #ffbc34;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #ffbc34;
  border-left-color: #ffbc34;
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #ffbc34;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ffbc34;
}

.ribbon-danger {
  background: #f62d51;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #f62d51;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #f62d51;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-danger:before {
  border-right-color: #f62d51;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #f62d51;
  border-left-color: #f62d51;
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #f62d51;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #f62d51;
}

.ribbon-default {
  background: #263238;
}

.ribbon-bookmark.ribbon-default:before {
  border-color: #263238;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-default:before {
  border-right-color: #263238;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-l.ribbon-default:before,
.ribbon-bookmark.ribbon-vertical-r.ribbon-default:before {
  border-right-color: #263238;
  border-bottom-color: transparent;
}

.ribbon-default.ribbon-corner:before {
  border-top-color: #263238;
  border-left-color: #263238;
}

.ribbon-default.ribbon-corner.ribbon-right:before {
  border-right-color: #263238;
  border-left-color: transparent;
}

.ribbon-default.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #263238;
}

/*******************
session ideal timeout page
******************/

#idletimeout {
  background: #003c69;
  border: 3px solid #003c69;
  color: #fff;
  font-family: arial, sans-serif;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 100000;
  display: none;
}

#idletimeout a {
  color: #ffffff;
  font-weight: bold;
}

#idletimeout span {
  font-weight: bold;
}

/*******************
Stylish tooltip
******************/

.mytooltip:hover .tooltip-content2,
.mytooltip:hover .tooltip-content2 i {
  opacity: 1;
  font-size: 18px;
  pointer-events: auto;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

.mytooltip:hover .tooltip-content4,
.mytooltip:hover .tooltip-text2 {
  pointer-events: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.mytooltip {
  display: inline;
  position: relative;
  z-index: 10;
}

.mytooltip:hover .tooltip-item::after {
  pointer-events: auto;
}

.mytooltip:hover .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
}

.mytooltip:hover .tooltip-content3 {
  opacity: 1;
  pointer-events: auto;
  transform: scale3d(1, 1, 1);
}

.mytooltip:hover .tooltip-item2 {
  color: #ffffff;
  transform: translate3d(0, -0.5em, 0);
}

.mytooltip:hover .tooltip-content5 {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0s;
}

.mytooltip:hover .tooltip-text3 {
  transition-delay: 0s;
  transform: scale3d(1, 1, 1);
}

.mytooltip:hover .tooltip-inner2 {
  transition-delay: 0.3s;
  transform: translate3d(0, 0, 0);
}

.tooltip-item {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 0 10px;
}

.tooltip-item::after {
  content: '';
  position: absolute;
  width: 360px;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  transform: translateX(-50%);
}

.tooltip-content {
  position: absolute;
  z-index: 9999;
  width: 360px;
  left: 50%;
  margin: 0 0 20px -180px;
  bottom: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
  background: #2b2b2b;
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.tooltip-content img {
  position: relative;
  height: 140px;
  display: block;
  float: left;
  margin-right: 1em;
}

.tooltip-effect-5 .tooltip-content {
  width: 180px;
  margin-left: -90px;
  transform-origin: 50% calc(106%);
  transform: rotate3d(0, 0, 1, 15deg);
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.tooltip-effect-5 .tooltip-text {
  padding: 1.4em;
}

.tooltip-content::after {
  content: '';
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #2a3035;
  border-width: 10px;
  margin-left: -10px;
}

.tooltip-text {
  font-size: 14px;
  line-height: 24px;
  display: block;
  padding: 1.31em 1.21em 1.21em 0;
  color: #ffffff;
}

.tooltip-content2 {
  position: absolute;
  z-index: 9999;
  width: 80px;
  height: 80px;
  padding-top: 25px;
  left: 50%;
  margin-left: -40px;
  bottom: 100%;
  border-radius: 50%;
  text-align: center;
  background: #003c69;
  color: #ffffff;
  opacity: 0;
  margin-bottom: 20px;
  cursor: default;
  pointer-events: none;
}

.tooltip-content2 i {
  opacity: 0;
}

.tooltip-effect-6 .tooltip-content2 {
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  transform-origin: 50% 100%;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-6 .tooltip-content2 i {
  transform: scale3d(0, 0, 1);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-6:hover .tooltip-content2 i {
  transform: rotate3d(1, 1, 1, 0);
}

.tooltip-content2::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin: -7px 0 0 -15px;
  width: 30px;
  height: 20px;
  background: url(../../images/backend/tooltip/tooltip1.svg) no-repeat center center;
  background-size: 100%;
}

.tooltip-content3 {
  position: absolute;
  background: url(../../images/backend/tooltip/shape1.svg) no-repeat center bottom;
  background-size: 100% 100%;
  z-index: 9999;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  padding: 50px 30px;
  text-align: center;
  color: #ffffff;
  opacity: 0;
  cursor: default;
  font-size: 14;
  line-height: 27px;
  pointer-events: none;
  transform: scale3d(0.1, 0.2, 1);
  transform-origin: 50% 120%;
  transition: opacity 0.4s, transform 0.4s;
  transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);
}

.tooltip-content3::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  left: 50%;
  margin-left: -8px;
  top: 100%;
  background: #00aeef;
  transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
}

.tooltip-item2 {
  color: #00aeef;
  cursor: pointer;
  z-index: 100;
  position: relative;
  display: inline-block;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.tooltip-content4 {
  position: absolute;
  z-index: 99;
  width: 360px;
  left: 50%;
  margin-left: -180px;
  bottom: -5px;
  text-align: left;
  background: #00aeef;
  opacity: 0;
  font-size: 14px;
  line-height: 27px;
  padding: 1.5em;
  color: #ffffff;
  border-bottom: 55px solid #2b2b2b;
  cursor: default;
  pointer-events: none;
  border-radius: 5px;
  transform: translate3d(0, -0.5em, 0);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-content4 a {
  color: #2b2b2b;
}

.tooltip-text2 {
  opacity: 0;
  transform: translate3d(0, 1.5em, 0);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-content5 {
  position: absolute;
  z-index: 9999;
  width: 300px;
  left: 50%;
  bottom: 100%;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  font-weight: 400;
  color: #ffffff;
  background: transparent;
  opacity: 0;
  margin: 0 0 20px -150px;
  cursor: default;
  pointer-events: none;
  transition: opacity 0.3s 0.3s;
}

.tooltip-content5 span {
  display: block;
}

.tooltip-text3 {
  border-bottom: 10px solid #003c69;
  overflow: hidden;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s 0.3s;
}

.tooltip-inner2 {
  background: #2b2b2b;
  padding: 40px;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.3s;
}

.tooltip-content5::after {
  content: '';
  bottom: -20px;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #003c69;
  border-width: 10px;
  margin-left: -10px;
}

.tooltip-effect-1 .tooltip-content {
  transform: translate3d(0, -10px, 0);
  transition: opacity 0.3s, transform 0.3s;
  color: #ffffff;
}

.tooltip-effect-2 .tooltip-content {
  transform-origin: 50% calc(110%);
  transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
  transform-origin: 50% 100%;
  transform: scale3d(0.7, 0.3, 1);
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip.tooltip-effect-2:hover .tooltip-content {
  transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
}

a.mytooltip {
  font-weight: 500;
  color: #003c69;
}

.tooltip-effect-7 .tooltip-content2 {
  transform: translate3d(0, 10px, 0);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-7 .tooltip-content2 i {
  transform: translate3d(0, 15px, 0);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-8 .tooltip-content2 {
  transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  transform-origin: 50% 100%;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-8 .tooltip-content2 i {
  transform: scale3d(0, 0, 1);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-9 .tooltip-content2 {
  transform: translate3d(0, -20px, 0);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-9 .tooltip-content2 i {
  transform: translate3d(0, 20px, 0);
  transition: opacity 0.3s, transform 0.3s;
}

/*******************
Error Page
******************/

.error-box {
  height: 100%;
  position: fixed;
  background: url(../../images/backend/background/error-bg.jpg) no-repeat center center #fff;
  width: 100%;
}

.error-box .footer {
  width: 100%;
  left: 0px;
  right: 0px;
}

.error-body {
  padding-top: 5%;
}

.error-body h1 {
  font-size: 210px;
  font-weight: 900;
  line-height: 210px;
}

/*******************
google map Page
******************/

.gmaps,
.gmaps-panaroma {
  height: 300px;
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #f2f7f8;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  background: #7460ee;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #7460ee;
}

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #7460ee;
}

/*******************
vector map Page
******************/

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 10px;
  height: 10px;
  line-height: 10px;
}

.jvectormap-zoomout {
  top: 40px;
}

/*******************
Seach listing Page
******************/

.search-listing {
  padding: 0px;
  margin: 0px;
}

.search-listing li {
  list-style: none;
  padding: 15px 0;
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
}

.search-listing li h3 {
  margin: 0px;
  font-size: 18px;
}

.search-listing li h3 a {
  color: #009efb;
}

.search-listing li h3 a:hover {
  text-decoration: underline;
}

.search-listing li a {
  color: #55ce63;
}

/*******************
Login register and recover password Page
******************/

.login-register {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  padding: 10% 0;
  position: fixed;
}

.login-box {
  width: 400px;
  margin: 0 auto;
}

.login-box .footer {
  width: 100%;
  left: 0px;
  right: 0px;
}

.login-box .social {
  display: block;
  margin-bottom: 30px;
}

#recoverform {
  display: none;
}

.login-sidebar {
  padding: 0px;
  margin-top: 0px;
}

.login-sidebar .login-box {
  right: 0px;
  position: absolute;
  height: 100%;
}

/*******************
FAQs Page
******************/

.minimal-faq .card {
  border: 0px;
}

.minimal-faq .card .card-header {
  background: #ffffff;
  padding: 20px 0;
  margin-top: 10px;
}

.minimal-faq .card .card-block {
  padding: 15px 0px;
}

/*******************
Pricing Page
******************/

.pricing-box {
  position: relative;
  text-align: center;
  margin-top: 30px;
}

.featured-plan {
  margin-top: 0px;
}

.featured-plan .pricing-body {
  padding: 60px 0;
  background: #ebf3f5;
  border: 1px solid #ddd;
}

.featured-plan .price-table-content .price-row {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

.pricing-body {
  border-radius: 0px;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
  border-bottom: 5px solid rgba(120, 130, 140, 0.13);
  vertical-align: middle;
  padding: 30px 0;
  position: relative;
}

.pricing-body h2 {
  position: relative;
  font-size: 56px;
  margin: 20px 0 10px;
  font-weight: 500;
}

.pricing-body h2 span {
  position: absolute;
  font-size: 15px;
  top: -10px;
  margin-left: -10px;
}

.price-table-content .price-row {
  padding: 20px 0;
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

.pricing-plan {
  padding: 0 15px;
}

.pricing-plan .no-padding {
  padding: 0px;
}

.price-lable {
  position: absolute;
  top: -10px;
  padding: 5px 10px;
  margin: 0 auto;
  display: inline-block;
  width: 100px;
  left: 0px;
  right: 0px;
}

/*******************
chat application Page
******************/

.chat-main-box {
  position: relative;
  overflow: hidden;
}

.chat-main-box .chat-left-aside {
  position: relative;
  width: 250px;
  float: left;
  z-index: 9;
  top: 0px;
  border-right: 1px solid rgba(120, 130, 140, 0.53);
}

.chat-main-box .chat-left-aside .open-panel {
  display: none;
  cursor: pointer;
  position: absolute;
  left: -webkit-calc(100% - 1px);
  top: 50%;
  z-index: 100;
  background-color: #fff;
  -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 0 100px 100px 0;
  line-height: 1;
  padding: 15px 8px 15px 4px;
}

.chat-main-box .chat-left-aside .chat-left-inner {
  position: relative;
}

.chat-main-box .chat-left-aside .chat-left-inner .form-control {
  height: 60px;
  padding: 15px;
  background-image: linear-gradient(#009efb, #009efb), linear-gradient(rgba(120, 130, 140, 0.53), rgba(120, 130, 140, 0.53));
}

.chat-main-box .chat-left-aside .chat-left-inner .style-none {
  padding: 0px;
}

.chat-main-box .chat-left-aside .chat-left-inner .style-none li {
  list-style: none;
  overflow: hidden;
}

.chat-main-box .chat-left-aside .chat-left-inner .style-none li a {
  padding: 20px;
}

.chat-main-box .chat-left-aside .chat-left-inner .style-none li a:hover,
.chat-main-box .chat-left-aside .chat-left-inner .style-none li a.active {
  background: #ebf3f5;
}

.chat-main-box .chat-right-aside {
  width: calc(100% - 250px);
  float: left;
}

.chat-main-box .chat-right-aside .chat-list {
  max-height: none;
  height: 100%;
  padding-top: 40px;
}

.chat-main-box .chat-right-aside .chat-list .chat-text {
  border-radius: 6px;
}

.chat-main-box .chat-right-aside .send-chat-box {
  position: relative;
}

.chat-main-box .chat-right-aside .send-chat-box .form-control {
  border: none;
  border-top: 1px solid rgba(120, 130, 140, 0.53);
  resize: none;
  height: 80px;
  padding-right: 180px;
}

.chat-main-box .chat-right-aside .send-chat-box .form-control:focus {
  border-color: rgba(120, 130, 140, 0.53);
}

.chat-main-box .chat-right-aside .send-chat-box .custom-send {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.chat-main-box .chat-right-aside .send-chat-box .custom-send .cst-icon {
  color: #4d6074;
  margin-right: 10px;
}

/*******************
Email inbox Page
******************/

.inbox-panel .list-group .list-group-item {
  border: 0px;
  border-radius: 0px;
  border-left: 3px solid transparent;
}

.inbox-panel .list-group .list-group-item a {
  color: #4d6074;
}

.inbox-panel .list-group .list-group-item.active,
.inbox-panel .list-group .list-group-item:hover {
  background: #f2f7f8;
  border-left: 3px solid #003c69;
}

.inbox-center .unread td {
  font-weight: 400;
}

.inbox-center td {
  vertical-align: middle;
  white-space: nowrap;
}

.inbox-center a {
  color: #4d6074;
  padding: 2px 0 3px 0;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.inbox-center .checkbox {
  margin-top: -13px;
  height: 20px;
}

/*******************
Contact app Page
******************/

/*left-aside-column*/

.contact-page-aside {
  position: relative;
}

.left-aside {
  position: absolute;
  border-right: 1px solid rgba(120, 130, 140, 0.53);
  padding: 20px;
  width: 250px;
  height: 100%;
}

.right-aside {
  padding: 20px;
  margin-left: 250px;
}

.contact-list td {
  vertical-align: middle;
  padding: 25px 10px;
}

.contact-list td img {
  width: 30px;
}

.list-style-none {
  margin: 0px;
  padding: 0px;
}

.list-style-none li {
  list-style: none;
  margin: 0px;
}

.list-style-none li.box-label a {
  font-weight: 500;
}

.list-style-none li.divider {
  margin: 10px 0;
  height: 1px;
  background: rgba(120, 130, 140, 0.53);
}

.list-style-none li a {
  padding: 15px 10px;
  display: block;
  color: #4d6074;
}

.list-style-none li a:hover {
  color: #003c69;
}

.list-style-none li a span {
  float: right;
}

.slimScrollBar {
  z-index: 10 !important;
}

/*******************
form material page
******************/

.form-control-line .form-control {
  box-shadow: none;
}

/*******************
/*Image Cropper Page
******************/

.docs-buttons .btn,
.docs-toggles .btn-group,
.docs-data .input-group {
  margin-bottom: 5px;
}

/*******************
/*Form Typahead Page
******************/

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-menu {
  width: 100%;
  background: #ffffff;
  border: 1px solid #90a4ae;
  border-radius: 5px;
  padding: .75rem 0;
}

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: .25rem .75rem;
  cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #7460ee;
  color: #ffffff;
}

.twitter-typeahead .empty-message {
  padding: 5px 10px;
  text-align: center;
}

.twitter-typeahead .rtl-typeahead .tt-menu {
  text-align: right;
}

.twitter-typeahead .league-name {
  margin: 0 10px 5px;
  padding: 7px 5px 10px;
  border-bottom: 1px solid #90a4ae;
}

.scrollable-dropdown .twitter-typeahead .tt-menu {
  max-height: 80px;
  overflow-y: auto;
}

.bootstrap-maxlength {
  margin-top: .5rem;
}

.table-responsive > .table-bordered {
  border: 1px solid rgba(120, 130, 140, 0.53);
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*Theme Colors*/

/*$themecolor: #009efb;*/

/*bootstrap Color*/

/*Light colors*/

/*Normal Color*/

/*Extra Variable*/

/*Preloader*/

.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: 240px;
  height: 100%;
  top: 0px;
  z-index: 20;
  padding-top: 70px;
  background: #fff;
  /*box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);*/
}

.fix-sidebar .left-sidebar {
  position: fixed;
}

/*******************
use profile section
******************/

.user-profile {
  text-align: center;
  position: relative;
}

.user-profile .profile-img {
  width: 50px;
  margin: 0 auto;
  border-radius: 100%;
}

.user-profile .profile-img::before {
  -webkit-animation: 2.5s blow 0s linear infinite;
  animation: 2.5s blow 0s linear infinite;
  position: absolute;
  content: '';
  width: 50px;
  height: 50px;
  top: 0;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 0;
}

@-webkit-keyframes blow {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}

@keyframes blow {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}

.user-profile .profile-img img {
  width: 100%;
  border-radius: 100%;
}

.user-profile .profile-text {
  padding: 5px 0;
  position: relative;
}

.user-profile .profile-text a {
  color: #4d6074;
}

.user-profile .dropdown-menu {
  left: 0px;
  right: 0px;
  width: 180px;
  margin: 0 auto;
}

/*******************
sidebar footer
******************/

.sidebar-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  transition: 0.2s ease-out;
  width: 240px;
  background: #e8eff0;
  border-top: 1px solid rgba(120, 130, 140, 0.53);
}

.sidebar-footer a {
  padding: 15px;
  width: 33.333337%;
  float: left;
  text-align: center;
  font-size: 18px;
}

/*******************
sidebar navigation
******************/

.scroll-sidebar {
  padding-top: 30px;
}

.collapse.in {
  display: block;
}

.sidebar-nav {
  background: #fff;
}

.sidebar-nav > ul {
  padding: 0px 0px 60px;
}

.sidebar-nav ul {
  margin: 0px;
}

.sidebar-nav ul li {
  list-style: none;
}

.sidebar-nav ul li a {
  color: #4d6074;
  padding: 14px 35px 14px 15px;
  display: block;
  font-size: 15px;
}

.sidebar-nav ul li a.active,
.sidebar-nav ul li a:hover {
  color: #003c69;
}

.sidebar-nav ul li a.active {
  font-weight: 400;
  color: #263238;
}

.sidebar-nav ul li ul {
  padding-left: 30px;
}

.sidebar-nav ul li ul li a {
  padding: 10px 35px 10px 15px;
}

.sidebar-nav ul li ul ul {
  padding-left: 20px;
}

.sidebar-nav ul li.nav-small-cap {
  font-size: 12px;
  padding: 14px 14px 14px 20px;
  color: #90a4ae;
  font-weight: 500;
}

.sidebar-nav ul li.nav-devider {
  height: 1px;
  background: rgba(120, 130, 140, 0.53);
  display: block;
  margin: 20px 0;
}

.sidebar-nav > ul > li.active > a {
  border-left: 3px solid #003c69;
  color: #003c69;
}

.sidebar-nav > ul > li.active > a i {
  color: #003c69;
}

.sidebar-nav > ul > li > a {
  border-left: 3px solid #fff;
}

.sidebar-nav > ul > li > a.active,
.sidebar-nav > ul > li > a:hover {
  border-left: 3px solid #003c69;
}

.sidebar-nav > ul > li > a.active i,
.sidebar-nav > ul > li > a:hover i {
  color: #003c69;
}

.sidebar-nav > ul > li > a i {
  width: 27px;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  color: #a6b7bf;
}

.sidebar-nav > ul > li > a .label {
  float: right;
  margin-top: 6px;
}

.sidebar-nav > ul > li > a.active {
  font-weight: 400;
  background: #242933;
  color: #003c69;
}

.sidebar-nav .has-arrow {
  position: relative;
}

.sidebar-nav .has-arrow::after {
  position: absolute;
  content: '';
  width: .4em;
  height: .4em;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #4d6074;
  right: 1em;
  -webkit-transform: rotate(-45deg) translate(0, -50%);
  -ms-transform: rotate(-45deg) translate(0, -50%);
  -o-transform: rotate(-45deg) translate(0, -50%);
  transform: rotate(-45deg) translate(0, -50%);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  top: 50%;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.sidebar-nav .active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

/****************
When click on sidebar toggler and also for tablet
*****************/

@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav li {
    position: relative;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
    position: absolute;
    left: 60px;
    top: 50px;
    width: 200px;
    z-index: 1001;
    background: #e8eff0;
    display: none;
    padding-left: 1px;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul {
    height: auto !important;
    overflow: auto;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul > li > a > .hide-menu {
    display: block;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul,
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul.collapse {
    display: block;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li > a.has-arrow:after {
    display: none;
  }

  .mini-sidebar .left-sidebar {
    width: 60px;
  }

  .mini-sidebar .user-profile {
    padding-bottom: 30px;
    width: 60px;
  }

  .mini-sidebar .scroll-sidebar {
    padding-bottom: 0px;
    position: absolute;
  }

  .mini-sidebar .hide-menu,
  .mini-sidebar .nav-small-cap,
  .mini-sidebar .sidebar-footer,
  .mini-sidebar .user-profile .profile-text {
    display: none;
  }

  .mini-sidebar .nav-devider {
    width: 60px;
  }

  .mini-sidebar .sidebar-nav {
    background: transparent;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li > a {
    padding: 14px 15px;
    width: 60px;
    height: 50px;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    width: 260px;
    background: #003c69;
    color: #ffffff;
    border-color: #003c69;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a i {
    color: #ffffff;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a .hide-menu {
    display: inline;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li.active > a {
    border-color: transparent;
  }

  .mini-sidebar .sidebar-nav #sidebarnav > li.active > a.active {
    border-color: #003c69;
  }
}

@media (max-width: 767px) {
  .mini-sidebar .left-sidebar {
    position: fixed;
  }

  .mini-sidebar .left-sidebar,
  .mini-sidebar .sidebar-footer {
    left: -240px;
  }

  .mini-sidebar.show-sidebar .left-sidebar,
  .mini-sidebar.show-sidebar .sidebar-footer {
    left: 0px;
  }
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*******************
/*User mail widgets*/

/*******************/

.topbar .top-navbar .mailbox {
  width: 300px;
}

.topbar .top-navbar .mailbox ul {
  padding: 0px;
}

.topbar .top-navbar .mailbox ul li {
  list-style: none;
}

.mailbox ul li .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
}

.mailbox ul li .nav-link {
  border-top: 1px solid rgba(120, 130, 140, 0.53);
  padding-top: 15px;
}

.mailbox .message-center {
  height: 200px;
  overflow: auto;
  position: relative;
}

.mailbox .message-center a {
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
  display: block;
  text-decoration: none;
  padding: 9px 15px;
}

.mailbox .message-center a:hover {
  background: #f2f7f8;
}

.mailbox .message-center a div {
  white-space: normal;
}

.mailbox .message-center a .user-img {
  width: 40px;
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 0;
}

.mailbox .message-center a .user-img img {
  width: 100%;
}

.mailbox .message-center a .user-img .profile-status {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 30px;
  position: absolute;
  top: 1px;
  width: 10px;
}

.mailbox .message-center a .user-img .online {
  background: #55ce63;
}

.mailbox .message-center a .user-img .busy {
  background: #f62d51;
}

.mailbox .message-center a .user-img .away {
  background: #ffbc34;
}

.mailbox .message-center a .user-img .offline {
  background: #ffbc34;
}

.mailbox .message-center a .mail-contnet {
  display: inline-block;
  width: 75%;
  vertical-align: middle;
}

.mailbox .message-center a .mail-contnet h5 {
  margin: 5px 0px 0;
}

.mailbox .message-center a .mail-contnet .mail-desc,
.mailbox .message-center a .mail-contnet .time {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4d6074;
  white-space: nowrap;
}

/*******************
/*Analytic info sparkline chart page*/

/*******************/

.analytics-info li span {
  font-size: 24px;
  vertical-align: middle;
}

/*******************
/*States row*/

/*******************/

.stats-row {
  margin-bottom: 20px;
}

.stats-row .stat-item {
  display: inline-block;
  padding-right: 15px;
}

.stats-row .stat-item + .stat-item {
  padding-left: 15px;
  border-left: 1px solid rgba(120, 130, 140, 0.53);
}

/*******************/

/*Dashboard1 Weather*/

/*******************/

.city-weather-days {
  margin: 0px;
}

.city-weather-days li {
  text-align: center;
  padding: 15px 0;
}

.city-weather-days li span {
  display: block;
  padding: 10px 0 0;
  color: #90a4ae;
}

.city-weather-days li i {
  display: block;
  font-size: 20px;
  color: #003c69;
}

.city-weather-days li h3 {
  font-weight: 300;
  margin-top: 5px;
}

/*******************/

/*Comment widgets*/

/*******************/

.comment-widgets {
  position: relative;
  margin-bottom: 10px;
}

.comment-widgets .comment-row {
  border-left: 3px solid #ffffff;
  padding: 15px;
}

.comment-widgets .comment-row:hover,
.comment-widgets .comment-row.active {
  border-color: #003c69;
}

.comment-text {
  padding: 15px 15px 15px 20px;
}

.comment-text:hover .comment-footer .action-icons,
.comment-text.active .comment-footer .action-icons {
  visibility: visible;
}

.comment-text p {
  max-height: 50px;
  overflow: hidden;
}

.comment-footer .action-icons {
  visibility: hidden;
}

.comment-footer .action-icons a {
  padding-left: 15px;
  vertical-align: middle;
  color: #90a4ae;
}

.comment-footer .action-icons a:hover,
.comment-footer .action-icons a.active {
  color: #009efb;
}

/*******************/

/*To do widgets*/

/*******************/

.todo-list li {
  border: 0px;
  margin-bottom: 0px;
  padding: 20px 15px 15px 0px;
}

.todo-list li .checkbox {
  width: 100%;
}

.todo-list li .checkbox label {
  font-weight: 400;
}

.todo-list li:last-child {
  border-bottom: 0px;
}

.todo-list li .assignedto {
  padding: 0px 0 0 27px;
  margin: 0px;
}

.todo-list li .assignedto li {
  list-style: none;
  padding: 0px;
  display: inline-block;
  border: 0px;
  margin-right: 2px;
}

.todo-list li .assignedto li img {
  width: 30px;
  border-radius: 100%;
}

.todo-list li .item-date {
  padding-left: 25px;
  font-size: 12px;
  display: inline-block;
}

.list-task .task-done span {
  text-decoration: line-through;
}

/*******************/

/*Chat widget*/

/*******************/

.chat-list {
  margin: 0px;
  padding: 0px;
}

.chat-list li {
  list-style: none;
  margin-top: 30px;
}

.chat-list li .chat-img {
  display: inline-block;
  width: 45px;
  vertical-align: top;
}

.chat-list li .chat-img img {
  width: 45px;
  border-radius: 100%;
}

.chat-list li .chat-content {
  width: calc(100% - 140px);
  display: inline-block;
  padding-left: 15px;
}

.chat-list li .chat-content h5 {
  color: #90a4ae;
}

.chat-list li .chat-content .box {
  display: inline-block;
  margin-bottom: 10px;
  color: #263238;
}

.chat-list li .chat-time {
  display: inline-block;
  text-align: right;
  width: 80px;
  font-size: 13px;
  color: #90a4ae;
}

.chat-list li.odd .chat-content {
  text-align: right;
  width: calc(100% - 90px);
}

.chat-list li.odd .box {
  clear: both;
}

.chat-list li.odd + .odd {
  margin-top: 0px;
}

.chat-list li.reverse {
  text-align: right;
}

.chat-list li.reverse .chat-time {
  text-align: left;
}

.chat-list li.reverse .chat-content {
  padding-left: 0px;
  padding-right: 15px;
}

/*******************/

/*Chat widget*/

/*******************/

.message-box ul li .drop-title {
  font-weight: 500;
  padding: 11px 20px 15px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
}

.message-box ul li .nav-link {
  border-top: 1px solid rgba(120, 130, 140, 0.53);
  padding-top: 15px;
}

.message-box .message-widget {
  position: relative;
}

.message-box .message-widget a {
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
  display: block;
  text-decoration: none;
  padding: 9px 15px;
}

.message-box .message-widget a:hover {
  background: #f2f7f8;
}

.message-box .message-widget a:last-child {
  border-bottom: 0px;
}

.message-box .message-widget a div {
  white-space: normal;
}

.message-box .message-widget a .user-img {
  width: 45px;
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 0;
}

.message-box .message-widget a .user-img img {
  width: 100%;
}

.message-box .message-widget a .user-img .profile-status {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 33px;
  position: absolute;
  top: -1px;
  width: 10px;
}

.message-box .message-widget a .user-img .online {
  background: #55ce63;
}

.message-box .message-widget a .user-img .busy {
  background: #f62d51;
}

.message-box .message-widget a .user-img .away {
  background: #ffbc34;
}

.message-box .message-widget a .user-img .offline {
  background: #ffbc34;
}

.message-box .message-widget a .mail-contnet {
  display: inline-block;
  width: 75%;
  vertical-align: middle;
}

.message-box .message-widget a .mail-contnet h5 {
  margin: 5px 0px 0;
}

.message-box .message-widget a .mail-contnet .mail-desc,
.message-box .message-widget a .mail-contnet .time {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4d6074;
  white-space: nowrap;
}

/*******************/

/*Calendar widget*/

/*******************/

.calendar {
  float: left;
  margin-bottom: 0px;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day {
  background: #ffffff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc-widget-header {
  border: 0px !important;
}

.fc-widget-content {
  border-color: rgba(120, 130, 140, 0.13) !important;
}

.fc th.fc-widget-header {
  color: #4d6074;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  padding: 7px 0px;
  text-transform: uppercase;
}

.fc th.fc-sun,
.fc th.fc-tue,
.fc th.fc-thu,
.fc th.fc-sat {
  background: #f2f7f8;
}

.fc th.fc-mon,
.fc th.fc-wed,
.fc th.fc-fri {
  background: #f2f7f8;
}

.fc-view {
  margin-top: 0px;
}

.fc-toolbar {
  margin: 0px;
  padding: 24px 0px;
}

.fc-button {
  background: #ffffff;
  border: 1px solid rgba(120, 130, 140, 0.53);
  color: #4d6074;
  text-transform: capitalize;
}

.fc-button:hover {
  background: #f2f7f8;
  opacity: 0.8;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #F5F5F5;
}

.fc-unthemed .fc-today {
  border: 1px solid #f62d51;
  background: #f2f7f8 !important;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #ffffff;
}

.fc-event {
  border-radius: 0px;
  border: none;
  cursor: move;
  color: #ffffff !important;
  font-size: 13px;
  margin: 1px -1px 0 -1px;
  padding: 5px 5px;
  text-align: center;
  background: #009efb;
}

.calendar-event {
  cursor: move;
  margin: 10px 5px 0 0;
  padding: 6px 10px;
  display: inline-block;
  color: #ffffff;
  min-width: 140px;
  text-align: center;
  background: #009efb;
}

.calendar-event a {
  float: right;
  opacity: 0.6;
  font-size: 10px;
  margin: 4px 0 0 10px;
  color: #ffffff;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}

.fc-basic-view .fc-day-number {
  padding: 10px 15px;
  display: inline-block;
}

/*******************/

/*Steam line widget*/

/*******************/

.steamline {
  position: relative;
  border-left: 1px solid rgba(120, 130, 140, 0.53);
  margin-left: 20px;
}

.steamline .sl-left {
  float: left;
  margin-left: -20px;
  z-index: 1;
  width: 40px;
  line-height: 40px;
  text-align: center;
  height: 40px;
  border-radius: 100%;
  color: #ffffff;
  background: #263238;
  margin-right: 15px;
}

.steamline .sl-left img {
  max-width: 40px;
}

.steamline .sl-right {
  padding-left: 50px;
}

.steamline .sl-right .desc,
.steamline .sl-right .inline-photos {
  margin-bottom: 30px;
}

.steamline .sl-item {
  border-bottom: 1px solid rgba(120, 130, 140, 0.53);
  margin: 20px 0;
}

.sl-date {
  font-size: 10px;
  color: #90a4ae;
}

.time-item {
  border-color: rgba(120, 130, 140, 0.53);
  padding-bottom: 1px;
  position: relative;
}

.time-item:before {
  content: " ";
  display: table;
}

.time-item:after {
  background-color: #ffffff;
  border-color: rgba(120, 130, 140, 0.53);
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}

.time-item-item:after {
  content: " ";
  display: table;
}

.item-info {
  margin-bottom: 15px;
  margin-left: 15px;
}

.item-info p {
  margin-bottom: 10px !important;
}

/*******************/

/*Feed widget*/

/*******************/

.feeds {
  margin: 0px;
  padding: 0px;
}

.feeds li {
  list-style: none;
  padding: 10px;
  display: block;
}

.feeds li:hover {
  background: #ebf3f5;
}

.feeds li > div {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
}

.feeds li > div i {
  line-height: 40px;
}

.feeds li span {
  float: right;
  width: auto;
  font-size: 12px;
}

/*******************/

/*Vertical carousel*/

/*******************/

.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

/*******************/

/*social-widgets*/

/*******************/

.social-widget .soc-header {
  padding: 15px;
  text-align: center;
  font-size: 36px;
  color: #fff;
}

.social-widget .soc-header.box-facebook {
  background: #3b5998;
}

.social-widget .soc-header.box-twitter {
  background: #00aced;
}

.social-widget .soc-header.box-google {
  background: #f86c6b;
}

.social-widget .soc-header.box-linkedin {
  background: #4875b4;
}

.social-widget .soc-content {
  display: flex;
  text-align: center;
}

.social-widget .soc-content div {
  padding: 10px;
}

.social-widget .soc-content div h3 {
  margin-bottom: 0px;
}

/*******************/

/*Guage chart*/

/*******************/

.gaugejs-box {
  position: relative;
  margin: 0 auto;
}

.gaugejs-box canvas.gaugejs {
  width: 100% !important;
  height: auto !important;
}

/*******************/

/*social-profile-first*/

/*******************/

.social-profile-first {
  text-align: center;
  padding-top: 22%;
  margin-bottom: 96px;
}

.social-profile-first.bg-over {
  background: rgba(56, 83, 161, 0.7);
}

.social-profile-first .middle {
  vertical-align: middle;
}

/*******************/

/*country-state*/

/*******************/

.country-state {
  list-style: none;
  margin: 0;
  padding: 0 0 0 10px;
}

.country-state li {
  margin-top: 30px;
  margin-bottom: 10px;
}

.country-state h2 {
  margin-bottom: 0px;
  font-weight: 400;
}

/*******************/

/*profile timeline widget*/

/*******************/

.profiletimeline {
  position: relative;
  margin-left: 70px;
  margin-right: 10px;
}

.profiletimeline .sl-left {
  float: left;
  margin-left: -60px;
  z-index: 1;
  margin-right: 15px;
}

.profiletimeline .sl-left img {
  max-width: 40px;
}

.profiletimeline .sl-item {
  margin-top: 8px;
  margin-bottom: 30px;
}

.profiletimeline .sl-date {
  font-size: 12px;
  color: #90a4ae;
}

.profiletimeline .time-item {
  border-color: rgba(120, 130, 140, 0.53);
  padding-bottom: 1px;
  position: relative;
}

.profiletimeline .time-item:before {
  content: " ";
  display: table;
}

.profiletimeline .time-item:after {
  background-color: #ffffff;
  border-color: rgba(120, 130, 140, 0.53);
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  content: '';
  height: 14px;
  left: 0;
  margin-left: -8px;
  position: absolute;
  top: 5px;
  width: 14px;
}

.profiletimeline .time-item-item:after {
  content: " ";
  display: table;
}

.profiletimeline .item-info {
  margin-bottom: 15px;
  margin-left: 15px;
}

.profiletimeline .item-info p {
  margin-bottom: 10px !important;
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

@media (min-width: 1600px) {
  .col-xlg-1,
  .col-xlg-10,
  .col-xlg-11,
  .col-xlg-12,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9 {
    float: left;
  }

  .col-xlg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xlg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66666667%;
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xlg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333333%;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xlg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xlg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66666667%;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xlg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333333%;
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xlg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xlg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66666667%;
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xlg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333333%;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xlg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xlg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66666667%;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xlg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333333%;
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xlg-pull-12 {
    right: 100%;
  }

  .col-xlg-pull-11 {
    right: 91.66666667%;
  }

  .col-xlg-pull-10 {
    right: 83.33333333%;
  }

  .col-xlg-pull-9 {
    right: 75%;
  }

  .col-xlg-pull-8 {
    right: 66.66666667%;
  }

  .col-xlg-pull-7 {
    right: 58.33333333%;
  }

  .col-xlg-pull-6 {
    right: 50%;
  }

  .col-xlg-pull-5 {
    right: 41.66666667%;
  }

  .col-xlg-pull-4 {
    right: 33.33333333%;
  }

  .col-xlg-pull-3 {
    right: 25%;
  }

  .col-xlg-pull-2 {
    right: 16.66666667%;
  }

  .col-xlg-pull-1 {
    right: 8.33333333%;
  }

  .col-xlg-pull-0 {
    right: auto;
  }

  .col-xlg-push-12 {
    left: 100%;
  }

  .col-xlg-push-11 {
    left: 91.66666667%;
  }

  .col-xlg-push-10 {
    left: 83.33333333%;
  }

  .col-xlg-push-9 {
    left: 75%;
  }

  .col-xlg-push-8 {
    left: 66.66666667%;
  }

  .col-xlg-push-7 {
    left: 58.33333333%;
  }

  .col-xlg-push-6 {
    left: 50%;
  }

  .col-xlg-push-5 {
    left: 41.66666667%;
  }

  .col-xlg-push-4 {
    left: 33.33333333%;
  }

  .col-xlg-push-3 {
    left: 25%;
  }

  .col-xlg-push-2 {
    left: 16.66666667%;
  }

  .col-xlg-push-1 {
    left: 8.33333333%;
  }

  .col-xlg-push-0 {
    left: auto;
  }

  .offset-xlg-12 {
    margin-left: 100%;
  }

  .offset-xlg-11 {
    margin-left: 91.66666667%;
  }

  .offset-xlg-10 {
    margin-left: 83.33333333%;
  }

  .offset-xlg-9 {
    margin-left: 75%;
  }

  .offset-xlg-8 {
    margin-left: 66.66666667%;
  }

  .offset-xlg-7 {
    margin-left: 58.33333333%;
  }

  .offset-xlg-6 {
    margin-left: 50%;
  }

  .offset-xlg-5 {
    margin-left: 41.66666667%;
  }

  .offset-xlg-4 {
    margin-left: 33.33333333%;
  }

  .offset-xlg-3 {
    margin-left: 25%;
  }

  .offset-xlg-2 {
    margin-left: 16.66666667%;
  }

  .offset-xlg-1 {
    margin-left: 8.33333333%;
  }

  .offset-xlg-0 {
    margin-left: 0;
  }
}

.col-xlg-1,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/*-------------------*/

/*Bootstrap 4 hack*/

/*-------------------*/

.bootstrap-touchspin .input-group-btn {
  align-items: normal;
}

.form-control-danger,
.form-control-success,
.form-control-warning {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right .5625rem;
  -webkit-background-size: 1.125rem 1.125rem;
  background-size: 1.125rem 1.125rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: #55ce63;
}

.has-success .form-control-success {
  background-image: url(../../images/backend/icon/success.svg);
}

.has-success .form-control {
  border-color: #55ce63;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: #ffbc34;
}

.has-warning .form-control-warning {
  background-image: url(../../images/backend/icon/warning.svg);
}

.has-warning .form-control {
  border-color: #ffbc34;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: #f62d51;
}

.has-danger .form-control-danger {
  background-image: url(../../images/backend/icon/danger.svg);
}

.has-danger .form-control {
  border-color: #f62d51;
}

.input-group-addon [type="radio"]:not(:checked),
.input-group-addon [type="radio"]:checked,
.input-group-addon [type="checkbox"]:not(:checked),
.input-group-addon [type="checkbox"]:checked {
  position: initial;
  opacity: 1;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.card-inverse .card-blockquote,
.card-inverse .card-footer,
.card-inverse .card-header,
.card-inverse .card-title {
  color: #ffffff;
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */

@media (min-width: 1650px) {
  .widget-app-columns {
    column-count: 3;
  }
}

/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */

@media (max-width: 1370px) {
  .widget-app-columns {
    column-count: 2;
  }
}

/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */

@media (min-width: 1024px) {
  .page-wrapper {
    margin-left: 240px;
  }

  .footer {
    left: 240px;
  }
}

@media (max-width: 1023px) {
  .page-wrapper {
    margin-left: 60px;
    transition: 0.2s ease-in;
  }

  .footer {
    left: 60px;
  }

  .widget-app-columns {
    column-count: 1;
  }

  .inbox-center a {
    width: 200px;
  }
}

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */

@media (min-width: 768px) {
  .navbar-header {
    width: 240px;
    flex-shrink: 0;
  }

  .navbar-header .navbar-brand {
    padding-top: 0px;
  }

  .material-icon-list-demo .icons div {
    width: 33%;
    display: inline-block;
    line-height: 26px;
  }

  .mini-sidebar .page-wrapper {
    margin-left: 60px;
  }

  .mini-sidebar .footer {
    left: 60px;
  }

  .flex-wrap {
    flex-wrap: no-wrap !important;
    -webkit-flex-wrap: no-wrap !important;
  }
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */

@media (max-width: 767px) {
  /*Header*/

  .topbar {
    position: fixed;
    width: 100%;
  }

  .topbar .top-navbar {
    padding-right: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    -webkit-align-items: center;
  }

  .topbar .top-navbar .navbar-collapse {
    display: flex;
    width: 100%;
  }

  .topbar .top-navbar .navbar-nav {
    flex-direction: row;
  }

  .topbar .top-navbar .navbar-nav > .nav-item.show {
    position: static;
  }

  .topbar .top-navbar .navbar-nav > .nav-item.show .dropdown-menu {
    width: 100%;
    margin-top: 0px;
  }

  .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    padding-left: .50rem;
    padding-right: .50rem;
  }

  .topbar .top-navbar .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .mega-dropdown .dropdown-menu {
    height: 480px;
    overflow: auto;
  }

  /*Sidebar and wrapper*/

  .mini-sidebar .page-wrapper {
    margin-left: 0px;
    padding-top: 70px;
  }

  .comment-text .comment-footer .action-icons {
    display: block;
    padding: 10px 0;
  }

  .vtabs .tabs-vertical {
    width: auto;
  }

  /*Footer*/

  .footer {
    left: 0px;
  }

  .material-icon-list-demo .icons div {
    width: 100%;
  }

  .error-page .footer {
    position: fixed;
    bottom: 0px;
    z-index: 10;
  }

  .error-box {
    position: relative;
    padding-bottom: 60px;
  }

  .error-body {
    padding-top: 10%;
  }

  .error-body h1 {
    font-size: 100px;
    font-weight: 600;
    line-height: 100px;
  }

  .login-register {
    position: relative;
    overflow: hidden;
  }

  .login-box {
    width: 90%;
  }

  .login-sidebar {
    padding: 10% 0;
  }

  .login-sidebar .login-box {
    position: relative;
  }

  /*This is for chat page*/

  .chat-main-box .chat-left-aside {
    left: -250px;
    position: absolute;
    transition: 0.5s ease-in;
    background: #ffffff;
  }

  .chat-main-box .chat-left-aside.open-pnl {
    left: 0px;
  }

  .chat-main-box .chat-left-aside .open-panel {
    display: block;
  }

  .chat-main-box .chat-right-aside {
    width: 100%;
  }

  /*Timeline*/

  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    top: 16px;
    left: 15px;
    margin-left: 0;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }

  ul.timeline > li > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }

  /*Contact app page*/

  .left-aside {
    width: 100%;
    position: relative;
    border: 0px;
  }

  .right-aside {
    margin-left: 0px;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
    -webkit-flex-wrap: wrap !important;
  }

  .chat-list li .chat-content {
    width: calc(100% - 80px);
  }

  .dataTables_wrapper .dataTables_paginate {
    width: 100%;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0.5rem 0.5rem;
  }
}

select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle;
}

.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  z-index: 1;
  text-align: right;
  white-space: nowrap;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
  color: rgba(255, 255, 255, 0.5);
}

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.bootstrap-select.is-invalid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select.is-valid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
  border-color: #28a745;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 220px;
}

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}

:not(.input-group) > .bootstrap-select.form-control:not([class*=col-]) {
  width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}

.bootstrap-select.dropdown-menu-right,
.bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0;
}

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0;
}

.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem;
}

.form-inline .bootstrap-select .form-control {
  width: 100%;
}

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed;
}

.bootstrap-select.disabled:focus,
.bootstrap-select > .disabled:focus {
  outline: none !important;
}

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important;
}

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select .dropdown-toggle:before {
  content: '';
  display: inline-block;
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  height: 100%;
  width: 100%;
  text-align: left;
}

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: inherit;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
}

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit;
}

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select .dropdown-menu > .inner:focus {
  outline: none !important;
}

.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}

.bootstrap-select .dropdown-menu li {
  position: relative;
}

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important;
}

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  user-select: none;
}

.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none;
}

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block;
}

.bootstrap-select .dropdown-menu li small {
  padding-left: 0.5em;
}

.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: whitesmoke;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0;
}

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline;
}

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  transform: rotate(45deg);
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}

/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*Theme Colors*/

/*$themecolor: #009efb;*/

/*bootstrap Color*/

/*Light colors*/

/*Normal Color*/

/*Extra Variable*/

/*Preloader*/

.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

body {
  min-height: 100vh;
}

body > #wrapper {
  min-height: 100vh;
}

body > #wrapper .login-register {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

body > #wrapper .login-register .form-error-icon.badge {
  display: none;
}

.navbar-brand {
  max-width: 100%;
  max-height: 70px;
  min-height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px;
}

.navbar-brand img.mini {
  display: none;
  margin-top: 0;
  max-width: calc(100% - 10px);
  margin-left: 5px;
}

.navbar-brand img {
  max-width: 100%;
  max-height: 80px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.mini-sidebar .navbar-brand {
  padding: 0;
}

.mini-sidebar .navbar-brand img {
  display: none;
}

.mini-sidebar .navbar-brand img.mini {
  display: block;
}

.topbar .top-navbar .app-search {
  margin-top: 0;
}

.topbar .top-navbar .navbar-nav {
  display: flex;
  align-items: center;
}

.topbar .top-navbar .navbar-nav > .nav-item {
  display: flex;
  justify-content: center;
}

.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
  padding-top: 2px;
}

.animationFast {
  animation-duration: 200ms;
}

.profile-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  font-size: 18px !important;
}

.dw-user-box {
  display: flex;
  flex-wrap: nowrap;
}

.delete-icon {
  position: absolute;
  margin-left: 10px;
  color: #f62d51;
  cursor: pointer;
}

.pagination {
  justify-content: flex-end;
}

.image-thumbnails img {
  max-width: 200px;
}

img.image-thumbnail {
  max-width: 200px;
}

img.image-icon {
  max-width: 32px;
}

img.img-list-thumbnail {
  max-height: 60px;
  width: auto;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

img.thumbnail180 {
  max-width: 180px;
}

img.thumbnail {
  max-height: 200px;
  width: auto;
}

form .form-buttons {
  display: flex;
  justify-content: flex-end;
}

form .form-buttons > .form-group {
  display: block;
  float: right;
  margin-left: 20px;
}

.topbar {
  z-index: 9999;
}

.dropzone .dz-preview .dz-progress {
  display: none !important;
}

.dropzone .dz-preview .dz-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.checkbox label {
  margin-bottom: 0;
}

label.form-check {
  cursor: pointer;
}

.issue-status:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  border-radius: 30px;
  vertical-align: middle;
}

.issue-status.status-pending:before {
  background-color: #ffc70e;
}

.issue-status.status-processing:before {
  background-color: #00aed9;
}

.issue-status.status-solved:before {
  background-color: #00a599;
}

.issue-status.status-not_applicable:before {
  background-color: #ef473a;
}

.issue-status.status-deleted:before {
  background-color: #ef473a;
}

.issue-status.status-postponed:before {
  background-color: #00b090;
}

.issue-status.status-revised:before {
  background-color: #7d5ccb;
}

.issue-status.status-assigned:before {
  background-color: #7d5ccb;
}

.issue-status.status-received:before {
  background-color: #00aed9;
}

.issue-status.status-transferred:before {
  background-color: #a5d86a;
}

.select2-more-info {
  margin-top: 15px;
  background-color: #f1effd;
  padding: 10px 20px;
  text-align: justify;
  margin-right: 15px;
  margin-left: 15px;
  width: calc(100% - 30px);
}

.select2-container--default .select2-results__group {
  background-color: #f1effd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 2em;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 320px;
}

.select2-container--default .select2-selection--single {
  border-color: #d9d9d9;
  height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 33px;
}

/* Globals */

.attachments-preview {
  display: flex;
}

.attachments-preview .attachment-preview {
  margin: 10px;
  width: 200px;
  min-height: 140px;
  font-size: 50px;
  background-color: #f1effd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachments-preview .attachment-preview.small {
  width: 150px;
  min-height: 100px;
  font-size: 40px;
}

.attachments-preview .attachment-preview img {
  max-width: 100%;
}

.attachments-preview .attachment-preview .attachment-file {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachments-preview .attachment-preview.attachment-image {
  background-color: transparent;
  align-items: flex-end;
}

.message-type-button {
  font-size: 18px;
}

.message-type-button.type-system {
  background-color: #009efb;
}

.message-type-button.type-issue {
  background-color: #f62d51;
}

.message-type-button.type-answer {
  background-color: #7460ee;
}

.message-type-button.type-message {
  background-color: #55ce63;
}

.message-type-button.type-alert {
  background-color: #ffbc34;
}

.message-type-button i {
  color: white;
}

.message-type-icon {
  font-size: 20px;
  width: 20px;
  text-align: center;
}

.message-type-icon.type-system {
  color: #009efb;
}

.message-type-icon.type-issue {
  color: #f62d51;
}

.message-type-icon.type-answer {
  color: #7460ee;
}

.message-type-icon.type-message {
  color: #55ce63;
}

.message-type-icon.type-alert {
  color: #ffbc34;
}

.show-more.collapse {
  font-size: 14px;
  line-height: 1.5;
}

.show-more.collapse p {
  margin-bottom: 1.5em;
}

.show-more.collapse:not(.show) {
  height: 42px !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.show-more.collapse.collapsing {
  min-height: 42px !important;
}

.show-more.collapse + a.collapsed .less {
  display: none;
}

.show-more.collapse + a.collapsed .more {
  display: block;
}

.show-more.collapse + a:not(.collapsed) .less {
  display: block;
}

.show-more.collapse + a:not(.collapsed) .more {
  display: none;
}

.show-more.collapse.collapsing + a .less {
  display: none !important;
}

.add-button-container {
  position: relative;
}

.add-button-container .add-file-button {
  position: absolute;
  right: 0;
  top: 0;
}

.modal-backdrop + .select2-container {
  z-index: 99999;
}

.list .list-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dee2e6;
}

.list .list-item .list-item-image {
  width: 200px;
  min-height: 140px;
  font-size: 50px;
  background-color: #f1effd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list .list-item .list-item-image.small {
  width: 150px;
  min-height: 100px;
  font-size: 40px;
}

.list .list-item .list-item-image img.image-thumbnail {
  max-width: 100%;
}

.list .list-item .list-item-image .attachment-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  z-index: 10000;
}

.modal-backdrop {
  z-index: 9999;
}

.form-group .select2.select2-container {
  min-width: 100%;
  max-width: 100%;
}

.scroll-sidebar {
  scroll-behavior: auto;
  padding-bottom: 30px;
}

.bootstrap-select .dropdown-menu li a.dropdown-item.active,
.bootstrap-select .dropdown-menu li a.dropdown-item:active,
.dropdown-item.active,
.dropdown-item:active {
  background: #df7a00;
  color: white;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-bottom: 5px;
  margin-bottom: 0px;
  margin-top: 2px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #ced4da 1px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.bootstrap-tagsinput {
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: 'X';
}

.bootstrap-tagsinput .tag {
  height: 100%;
  margin-top: 0;
  float: left;
  font-size: 1rem;
  line-height: 1.4em;
}

/* Helpers */

.flex-nowrap {
  flex-wrap: nowrap;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-green {
  background-color: #00a599;
}

.bg-white {
  background-color: #ffffff;
}

.bg-light {
  background-color: #f1effd;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.d-flex > .card {
  width: 100%;
}

.image-rounded-wrapper {
  position: relative;
  box-shadow: 2px 2px 7px #eee;
  border-radius: 1000px;
  width: 100%;
  padding-bottom: 100%;
  margin: 1em auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
}

.image-rounded-wrapper img {
  object-fit: cover;
  position: absolute;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}

.grey {
  color: #a6b7bf;
}

.light {
  font-weight: 100;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

body.edit-issue .issue_status .dropdown-menu {
  z-index: 9999;
}

.bootstrap-select > .dropdown-toggle {
  height: 100%;
}

[data-toggle="collapse"].collapsed .fa-chevron-up {
  transform: rotate(180deg);
}

.c-pointer {
  cursor: pointer;
}

.h-with-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.r-0 {
  right: 0 !important;
}

.r-1 {
  right: 0.25rem !important;
}

.r-2 {
  right: 0.5rem !important;
}

.r-3 {
  right: 1rem !important;
}

.r-4 {
  right: 1.5rem !important;
}

.r-5 {
  right: 3rem !important;
}

.l-0 {
  left: 0 !important;
}

.l-1 {
  left: 0.25rem !important;
}

.l-2 {
  left: 0.5rem !important;
}

.l-3 {
  left: 1rem !important;
}

.l-4 {
  left: 1.5rem !important;
}

.l-5 {
  left: 3rem !important;
}

.t-0 {
  top: 0 !important;
}

.t-1 {
  top: 0.25rem !important;
}

.t-2 {
  top: 0.5rem !important;
}

.t-3 {
  top: 1rem !important;
}

.t-4 {
  top: 1.5rem !important;
}

.t-5 {
  top: 3rem !important;
}

.b-0 {
  bottom: 0 !important;
}

.b-1 {
  bottom: 0.25rem !important;
}

.b-2 {
  bottom: 0.5rem !important;
}

.b-3 {
  bottom: 1rem !important;
}

.b-4 {
  bottom: 1.5rem !important;
}

.b-5 {
  bottom: 3rem !important;
}

.text-small {
  font-size: 0.8em;
}

/* Multiple files custom widget */

.custom-multi-files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.custom-multi-files .new-file-widget {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

/* Issues */

body.edit-issue .issue-images {
  display: flex;
  flex-wrap: wrap;
}

body.edit-issue .issue-images .issue-images-item {
  position: relative;
  width: 25%;
}

body.edit-issue .issue-images .issue-images-item.dz-preview {
  padding-bottom: 30px !important;
}

body.edit-issue .issue-images .issue-images-item:first-of-type {
  width: 100%;
}

body.edit-issue .issue-images .issue-images-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

body.edit-issue .issue-images .issue-images-item .image-details {
  opacity: 0;
  position: absolute;
  top: 0;
  height: calc(100% - 30px);
  width: 100%;
  padding: 5px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: opacity 200ms ease-in-out;
}

body.edit-issue .issue-images .issue-images-item:hover .image-details {
  opacity: 1;
}

body.edit-issue .issue-images .issue-images-item a.image-popup {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: inline-block;
  color: #4d6074;
}

body.edit-issue .issue-images .issue-images-item a.image-popup .image-details {
  height: 100%;
}

body.edit-issue button[data-id="form_status"] .filter-option {
  margin-left: 25px;
  line-height: 24px;
}

body.edit-issue button[data-id="form_status"]:after {
  margin-top: 4px;
}

body.edit-issue button[data-id="form_status"]:before,
body.edit-issue .option_status.active:before,
body.edit-issue .option_status:before {
  content: '';
  border-radius: 20px;
  width: 15px;
  height: 15px;
  float: left;
  margin-right: 10px;
}

body.edit-issue .option_status:before {
  margin-top: 3px;
}

body.edit-issue button[data-class="option_status_pending"]:before,
body.edit-issue .option_status_pending.active:before,
body.edit-issue .option_status_pending:before {
  background-color: #ffc70e !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_processing"]:before,
body.edit-issue .option_status_processing.active:before,
body.edit-issue .option_status_processing:before {
  background-color: #00aed9 !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_solved"]:before,
body.edit-issue .option_status_solved.active:before,
body.edit-issue .option_status_solved:before {
  background-color: #00a599 !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_not_applicable"]:before,
body.edit-issue .option_status_not_applicable.active:before,
body.edit-issue .option_status_not_applicable:before {
  background-color: #ef473a !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_deleted"]:before,
body.edit-issue .option_status_deleted.active:before,
body.edit-issue .option_status_deleted:before {
  background-color: #ef473a !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_postponed"]:before,
body.edit-issue .option_status_postponed.active:before,
body.edit-issue .option_status_postponed:before {
  background-color: #00b090 !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_revised"]:before,
body.edit-issue .option_status_revised.active:before,
body.edit-issue .option_status_revised:before {
  background-color: #7d5ccb !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_assigned"]:before,
body.edit-issue .option_status_assigned.active:before,
body.edit-issue .option_status_assigned:before {
  background-color: #7d5ccb !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_received"]:before,
body.edit-issue .option_status_received.active:before,
body.edit-issue .option_status_received:before {
  background-color: #00aed9 !important;
  color: inherit;
}

body.edit-issue button[data-class="option_status_transferred"]:before,
body.edit-issue .option_status_transferred.active:before,
body.edit-issue .option_status_transferred:before {
  background-color: #a5d86a !important;
  color: inherit;
}

body.edit-issue .issue_status .option_status {
  border-radius: 0;
}

body.edit-issue .issue_status .option_status:hover,
body.edit-issue .issue_status .option_status:focus {
  color: #4d6074 !important;
}

body.edit-issue .issue_status li.active .option_status {
  font-style: italic;
}

body.edit-issue .issue_status .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
}

body.edit-issue .issue_status .dropdown-menu li {
  border-bottom: 1px solid grey;
}

body.edit-issue .issue_status .dropdown-menu li:last-of-type {
  border-bottom: none;
}

/* Sidebar */

.sidebar-nav ul li.nav-small-cap {
  font-size: 13px;
  text-transform: uppercase;
}

/* View entity */

/* User profile */

.view-entity .entity-image-wrapper,
.view-entity .user-image-wrapper,
.profile-user .entity-image-wrapper,
.profile-user .user-image-wrapper {
  padding: 20px 18%;
}

.view-entity .entity-image-wrapper .change-image-overlay,
.view-entity .user-image-wrapper .change-image-overlay,
.profile-user .entity-image-wrapper .change-image-overlay,
.profile-user .user-image-wrapper .change-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: white;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.view-entity .entity-image-wrapper:hover .change-image-overlay,
.view-entity .user-image-wrapper:hover .change-image-overlay,
.profile-user .entity-image-wrapper:hover .change-image-overlay,
.profile-user .user-image-wrapper:hover .change-image-overlay {
  opacity: 1;
}

/* Messages */

.message-row-status-new_answer {
  background-color: rgba(255, 0, 0, 0.05);
}

.message-row-status-new {
  background-color: rgba(0, 0, 255, 0.05);
}

/* Filters */

.filters-wrapper .filter-item {
  position: relative;
  margin-bottom: 8px;
}

.filters-wrapper .filter-item .filter-label {
  display: flex;
  line-height: 40px;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
}

.filters-wrapper .filter-item .filter-label i {
  height: 30px;
  width: 32px;
  min-width: 32px;
  background: grey;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 10px;
}

.filters-wrapper .filter-item .filter-label label {
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filters-wrapper .filter-item .filter-label.not-empty {
  background-color: #f1effd;
}

.filters-wrapper .filter-item.active .filter-label {
  background-color: #f1effd;
}

.filters-wrapper .filter-item .filter-content {
  display: none;
  position: absolute;
  width: calc(100% - 30px);
  padding: 10px;
  border: 1px solid #f1effd;
  box-shadow: 1px 2px 8px #f1effd;
  z-index: 9999;
  background-color: white;
}

.filters-wrapper .filter-item .filter-content .select2,
.filters-wrapper .filter-item .filter-content select {
  width: 100% !important;
}

.btn#location-place {
  position: absolute;
  right: 30px;
  top: -30px;
}

.select2-dropdown {
  z-index: 9999;
}

/* Issues map */

#issues-map {
  height: 400px;
}

#issues-map .leaflet-div-icon {
  background-color: transparent;
  border: none;
}

#issues-map .div-marker i {
  font-size: 36px;
  text-shadow: 5px -3px 4px white;
}

#issues-map .div-marker i.marker-status-pending {
  color: #ffc70e;
}

#issues-map .div-marker i.marker-status-processing {
  color: #00aed9;
}

#issues-map .div-marker i.marker-status-solved {
  color: #00a599;
}

#issues-map .div-marker i.marker-status-not_applicable {
  color: #ef473a;
}

#issues-map .div-marker i.marker-status-deleted {
  color: #ef473a;
}

#issues-map .div-marker i.marker-status-postponed {
  color: #00b090;
}

#issues-map .div-marker i.marker-status-revised {
  color: #7d5ccb;
}

#issues-map .div-marker i.marker-status-assigned {
  color: #7d5ccb;
}

#issues-map .div-marker i.marker-status-received {
  color: #00aed9;
}

#issues-map .div-marker i.marker-status-transferred {
  color: #a5d86a;
}

#form_issue_types .form-check {
  margin-bottom: 10px;
}

#form_issue_types .form-check-label {
  margin-left: 10px;
  cursor: pointer;
}

.select2-option-with-icon img {
  height: 32px;
  margin-right: 5px;
}

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

#form_status {
  /*
    input[type="checkbox"] + .form-check-label:after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 8px;
        border-radius: 30px;
        vertical-align: middle;
    }
    input[value="pending"] + .form-check-label:after {
        background-color: $statusPending;
    }
    input[value="processing"] + .form-check-label:after {
        background-color: $statusProcessing;
    }
    input[value="solved"] + .form-check-label:after {
        background-color: $statusSolved;
    }
    input[value="not_applicable"] + .form-check-label:after {
        background-color: $statusNotApplicable;
    }
    input[value="deleted"] + .form-check-label:after {
        background-color: $statusDeleted;
    }
    input[value="postponed"] + .form-check-label:after {
        background-color: $statusPostponed;
    }
    input[value="revised"] + .form-check-label:after {
        background-color: $statusRevised;
    }
    input[value="assigned"] + .form-check-label:after {
        background-color: $statusAssigned;
    }
    input[value="received"] + .form-check-label:after {
        background-color: $statusReceived;
    }
    */
}

#form_status input[value="pending"] + .form-check-label:before {
  background-color: #ffc70e !important;
}

#form_status input[value="processing"] + .form-check-label:before {
  background-color: #00aed9 !important;
}

#form_status input[value="solved"] + .form-check-label:before {
  background-color: #00a599 !important;
}

#form_status input[value="not_applicable"] + .form-check-label:before {
  background-color: #ef473a !important;
}

#form_status input[value="deleted"] + .form-check-label:before {
  background-color: #ef473a !important;
}

#form_status input[value="postponed"] + .form-check-label:before {
  background-color: #00b090 !important;
}

#form_status input[value="revised"] + .form-check-label:before {
  background-color: #7d5ccb !important;
}

#form_status input[value="assigned"] + .form-check-label:before {
  background-color: #7d5ccb !important;
}

#form_status input[value="received"] + .form-check-label:before {
  background-color: #00aed9 !important;
}

#form_status input[value="transferred"] + .form-check-label:before {
  background-color: #a5d86a !important;
}

.sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  opacity: 0.6;
}

i.fa.large {
  font-size: 24px;
}

.logout-link i {
  font-size: 30px;
  color: #df7a00;
}

.table-responsive .table {
  color: #000000;
}

.table th,
.table td {
  vertical-align: middle;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
}

.clickable-row:hover {
  background-color: #f9f8fe;
}

.card-status-pending {
  background-color: #ffc70e;
}

.card-status-processing {
  background-color: #00aed9;
}

.card-status-solved {
  background-color: #00a599;
}

.card-status-not_applicable {
  background-color: #ef473a;
}

.card-status-deleted {
  background-color: #ef473a;
}

.card-status-postponed {
  background-color: #00b090;
}

.card-status-revised {
  background-color: #7d5ccb;
}

.card-status-assigned {
  background-color: #7d5ccb;
}

.card-status-received {
  background-color: #00aed9;
}

.card-status-transferred {
  background-color: #a5d86a;
}

.img-issue-type-list {
  width: 32px;
  margin-right: 10px;
}

.text-italic {
  font-style: italic;
}

.dashboard-colaboration-wrapper {
  position: absolute;
  bottom: 80px;
  right: 30px;
  text-align: right;
}

.dashboard-colaboration-wrapper .logo-fundacion-dashboard {
  max-width: 140px;
}

.dashboard-colaboration-wrapper .text-colaboration-dashboard {
  font-weight: 400;
}

.dashboard-colaboration-wrapper .logo-zp-dashboard {
  max-width: 100px;
  margin-right: 50px;
}

.dashboard-wrapper {
  padding-bottom: 90px;
}

.topbar .profile-pic {
  width: 30px;
  height: 30px;
}

.btn-cursor-default {
  cursor: default;
}

.white-space-nowrap {
  white-space: nowrap;
}

label.choice_category {
  padding-left: 5px;
  position: relative;
  line-height: 25px;
  cursor: pointer;
  margin-left: 25px;
}

label.choice_category::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 1px;
  border: 1px solid rgba(120, 130, 140, 0.53);
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  top: 3px;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none;
}

label.choice_category::after {
  color: #263238;
  display: inline-block;
  font-size: 11px;
  height: 16px;
  left: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  position: absolute;
  top: -2px;
  width: 16px;
}

label.choice_category.selected::after {
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.checkbox-nopadding .checkbox {
  padding-left: 0;
}

.show-on-pdf {
  display: none;
}

.toast-copy {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999;
}

.pagination-info {
  line-height: 40px;
}

.checkbox.message-select-all {
  position: absolute;
  left: 13px;
  top: 14px;
}

#reports-chart {
  width: 100%;
  height: 400px;
}

#form_normatives .d-flex .form-group {
  flex-grow: 1;
  margin-left: 15px;
  margin-right: 15px;
  width: 33%;
}

#form_normatives .collection-widget {
  display: flex;
}

#form_normatives .collection-widget fieldset {
  width: 100%;
}

#form_normatives .collection-widget .remove-button {
  height: 40px;
  margin-top: 30px;
}

#form_normatives label {
  font-size: 0.9em;
}

.select2-more-info-normative {
  margin-top: 15px;
  background-color: #f1effd;
  padding: 10px 20px;
  text-align: justify;
  margin-right: 15px;
  margin-left: 15px;
  width: calc(100% - 30px);
}

.issue-image-gallery-item {
  width: 60px;
  height: 60px;
  padding: 5px;
  display: inline-block;
  white-space: normal;
  overflow: hidden;
}

.issue-image-gallery-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

#reports-map {
  display: none;
  text-align: center;
  min-height: 100vh;
}

#reports-map > svg {
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
}

#issue-location {
  height: 280px;
}

#issue-location .leaflet-div-icon {
  background-color: transparent;
  border: none;
}

#issue-location .div-marker i {
  font-size: 42px;
  text-shadow: 3px -2px 2px lightgrey;
}

