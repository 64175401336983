#issue-location {
    height: 280px;
    .leaflet-div-icon {
        background-color: transparent;
border: none;
    }
    .div-marker {
        i {
            font-size: 42px;
            text-shadow: 3px -2px 2px lightgrey;
        }
    }
}
