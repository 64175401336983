/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

@import 'variable';
//$themecolor: #009efb;
//cocemfe-override
$themecolor: #df7a00;
$themecolor-dark: #028ee1;
$topbar: #009efb;
$cocemfeBlue: #003c69!default;
$cocemfeOrange: #df7a00!default;
$cocemfeGreen: #00a599!default;

/*******************
/*Top bar
*******************/

.topbar {
    background: $topbar;
    /* Old browsers */
    background: -moz-linear-gradient(left, #00a599 0%, #00a599 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #00a599 0%, #00a599 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #00a599 0%, #00a599 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    .navbar-header {
        background: $white;
        box-shadow: 4px -4px 10px rgba(0, 0, 0, 0.05);
    }
    .navbar-light .navbar-nav .nav-item > {
        a.nav-link {
            color: rgba(255, 255, 255, 0.8)!important;
            &:hover,
            &:focus {
                color: $white!important;
            }
        }
    }
}
.logo-center .topbar {
    .navbar-header {
        background: transparent;
        box-shadow: none;
    }
    .top-navbar .navbar-header .navbar-brand .dark-logo{
        display: none;
    }
    .top-navbar .navbar-header .navbar-brand .light-logo{
        display: inline-block;
        color:rgba(255, 255, 255, 0.8);
    }
}

/*******************
/*General Elements
*******************/

a.link {
    &:hover,
    &:focus {
        color: $themecolor!important;
    }
}

.bg-theme {
    background-color: $themecolor !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: $themecolor;
    border-color: $themecolor;
}

.right-sidebar {
    .rpanel-title {
        background: $themecolor;
    }
}

.stylish-table {
    tbody tr {
        &:hover,
        &.active {
            border-left: 4px solid $themecolor;
        }
    }
}
.text-themecolor{
    color:$themecolor!important;
}
.profile-tab,
.customtab {
    li {
        a.nav-link {
            &.active {
                border-bottom: 2px solid $themecolor;
                color: $themecolor;
            }
            &:hover {
                color: $themecolor;
            }
        }
    }
}


/*******************
/*Buttons
*******************/

.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;
    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }
    &.active,
    &:focus {
        background: $themecolor-dark;
    }
}


/*******************
/*sidebar navigation
*******************/

.sidebar-nav {
    background: $sidebar;
    ul {
        li {
            a {
                color: $cocemfeBlue;
                &.active,
                &:hover {
                    color: $cocemfeGreen;
                }
                &.active {
                    color: $cocemfeGreen;
                }
            }
            &.nav-small-cap {
                color: $muted;
            }
        }
    }
    > ul > li {
        &.active > a {
            border-left: 3px solid $cocemfeGreen;
            color: $cocemfeGreen;
            i {
                color: $cocemfeGreen;
            }
        }
    }
    > ul > li > a {
        border-left: 3px solid $sidebar;
        &.active,
        &:hover {
            border-left: 3px solid $cocemfeGreen;
            i {
                color: $cocemfeGreen;
            }
        }
        i {
            color: $cocemfeBlue;
        }
        &.active {
            font-weight: 400;
            background: $white;
            color: $cocemfeGreen;
        }
    }
}
